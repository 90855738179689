import { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { config, getDocument } from "../../lib/appwrite";
import { PaypalCheckout } from "../../components";

const Checkout = () => {
    const { cartItems, setCartItems } = useStateContext();

    const [products, setProducts] = useState([]);
    const [subtotal, setSubtotal] = useState(0.0);
    const [shippingTotal, setShippingTotal] = useState(0.0)
    const [salesTax, setSalesTax] = useState(0.0);
    const [orderTotal, setOrderTotal] = useState(0.0);

    useEffect(() => {
        const fetchProducts = async () => {
            const combinedItems = [];

            cartItems.forEach(item => {
                // console.log(item)
                const existingItemIndex = combinedItems.findIndex(combinedItem =>
                    combinedItem.name === item.name &&
                    combinedItem.photo === item.photo &&
                    combinedItem.price === item.price &&
                    combinedItem.selectedColor === item.selectedColor &&
                    combinedItem.selectedProduct === item.selectedProduct &&
                    combinedItem.selectedSize === item.selectedSize
                );

                if (existingItemIndex !== -1) {
                    combinedItems[existingItemIndex].qty += 1;
                } else {
                    combinedItems.push({ ...item, qty: 1 });
                }
            });

            const updatedCartItems = await Promise.all(combinedItems.map(async (item) => {
                const itemData = await getDocument(config.merchandiseCollectionId, item.selectedProduct)
                const selectedSize = item.selectedSize;
                const selectedColor = item.selectedColor;
                const variants = JSON.parse(itemData.variants);

                // Find the variant with the matching size and color
                const variant = variants.find(
                    variant => variant.size === selectedSize && variant.color === selectedColor
                );

                return {
                    ...item,
                    inStock: variant ? parseInt(variant.stock) > 0 : false
                };
            }));


            setProducts(updatedCartItems);

            const total = combinedItems.reduce((acc, item) => acc + parseFloat(item.price) * parseInt(item.qty), 0);
            setSubtotal(total);

            // Update cart items

        };

        fetchProducts();
    }, [cartItems]);

    const [selectedState, setSelectedState] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');

    const states = [
        { code: 'AL', name: 'Alabama', taxRate: 0.04 },
        { code: 'AK', name: 'Alaska', taxRate: 0.00 }, // Alaska does not have a state sales tax
        { code: 'AZ', name: 'Arizona', taxRate: 0.056 },
        { code: 'AR', name: 'Arkansas', taxRate: 0.065 },
        { code: 'CA', name: 'California', taxRate: 0.075 },
        { code: 'CO', name: 'Colorado', taxRate: 0.029 },
        { code: 'CT', name: 'Connecticut', taxRate: 0.0635 },
        { code: 'DE', name: 'Delaware', taxRate: 0.00 }, // Delaware does not have a state sales tax
        { code: 'FL', name: 'Florida', taxRate: 0.06 },
        { code: 'GA', name: 'Georgia', taxRate: 0.04 },
        { code: 'HI', name: 'Hawaii', taxRate: 0.04 },
        { code: 'ID', name: 'Idaho', taxRate: 0.06 },
        { code: 'IL', name: 'Illinois', taxRate: 0.0625 },
        { code: 'IN', name: 'Indiana', taxRate: 0.07 },
        { code: 'IA', name: 'Iowa', taxRate: 0.06 },
        { code: 'KS', name: 'Kansas', taxRate: 0.065 },
        { code: 'KY', name: 'Kentucky', taxRate: 0.06 },
        { code: 'LA', name: 'Louisiana', taxRate: 0.04 },
        { code: 'ME', name: 'Maine', taxRate: 0.055 },
        { code: 'MD', name: 'Maryland', taxRate: 0.06 },
        { code: 'MA', name: 'Massachusetts', taxRate: 0.0625 },
        { code: 'MI', name: 'Michigan', taxRate: 0.06 },
        { code: 'MN', name: 'Minnesota', taxRate: 0.068 },
        { code: 'MS', name: 'Mississippi', taxRate: 0.07 },
        { code: 'MO', name: 'Missouri', taxRate: 0.0425 },
        { code: 'MT', name: 'Montana', taxRate: 0.00 }, // Montana does not have a state sales tax
        { code: 'NE', name: 'Nebraska', taxRate: 0.055 },
        { code: 'NV', name: 'Nevada', taxRate: 0.0685 },
        { code: 'NH', name: 'New Hampshire', taxRate: 0.00 }, // New Hampshire does not have a state sales tax
        { code: 'NJ', name: 'New Jersey', taxRate: 0.066 },
        { code: 'NM', name: 'New Mexico', taxRate: 0.05125 },
        { code: 'NY', name: 'New York', taxRate: 0.04 },
        { code: 'NC', name: 'North Carolina', taxRate: 0.0475 },
        { code: 'ND', name: 'North Dakota', taxRate: 0.05 },
        { code: 'OH', name: 'Ohio', taxRate: 0.0575 },
        { code: 'OK', name: 'Oklahoma', taxRate: 0.045 },
        { code: 'OR', name: 'Oregon', taxRate: 0.00 }, // Oregon does not have a state sales tax
        { code: 'PA', name: 'Pennsylvania', taxRate: 0.06 },
        { code: 'RI', name: 'Rhode Island', taxRate: 0.07 },
        { code: 'SC', name: 'South Carolina', taxRate: 0.06 },
        { code: 'SD', name: 'South Dakota', taxRate: 0.04 },
        { code: 'TN', name: 'Tennessee', taxRate: 0.07 },
        { code: 'TX', name: 'Texas', taxRate: 0.0625 },
        { code: 'UT', name: 'Utah', taxRate: 0.0485 },
        { code: 'VT', name: 'Vermont', taxRate: 0.06 },
        { code: 'VA', name: 'Virginia', taxRate: 0.04 },
        { code: 'WA', name: 'Washington', taxRate: 0.065 },
        { code: 'WV', name: 'West Virginia', taxRate: 0.06 },
        { code: 'WI', name: 'Wisconsin', taxRate: 0.05 },
        { code: 'WY', name: 'Wyoming', taxRate: 0.04 }
    ];

    useEffect(() => {
        const state = states.find(s => s.code === selectedState);
        const taxRate = state ? state.taxRate : 0;

        var sales_tax = 0; // Initialize sales_tax

        const updatedProducts = products.map(product => {
            const tax = (product.price * product.qty) * taxRate;
            sales_tax += tax; // Accumulate the tax
            return { ...product, tax };
        });

        // Round the sales tax to 2 decimal places
        const roundedSalesTax = parseFloat(sales_tax.toFixed(2));
        setSalesTax(roundedSalesTax);
        setProducts(updatedProducts);
        calculateTotal()
    }, [selectedState]);

    const countries = [
        { code: 'US', name: 'United States' },
        // { code: 'CA', name: 'Canada' },
        // Add more countries here
    ];

    const calculateSalesTax = (stateCode, countryCode) => {
        // Example tax rate logic. Expand as needed.
        const state = states.find(s => s.code === stateCode);
        if (state) {
            return state.taxRate;
        }

        // Fallback tax rate for countries if needed
        if (countryCode === 'CA') {
            return 0.05; // Example: Canada tax rate
        }

        return 0; // No tax
    };

    useEffect(() => {
        calculateTotal();
    }, [subtotal, shippingTotal, salesTax]);

    const calculateTotal = () => {
        const total = parseFloat(subtotal.toFixed(2)) + parseFloat(shippingTotal.toFixed(2)) + parseFloat(salesTax.toFixed(2));
        setOrderTotal(parseFloat(total.toFixed(2)));
    };

    return (
        <div className="bg-white pt-20">
            {/* Background color split screen for large screens */}
            <div aria-hidden="true" className="fixed left-0 top-0 hidden h-full w-1/2 bg-white lg:block" />
            <div aria-hidden="true" className="fixed right-0 top-0 hidden h-full w-1/2 bg-indigo-900 lg:block" />

            <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 lg:pt-16">
                <h1 className="sr-only">Checkout</h1>

                <section
                    aria-labelledby="summary-heading"
                    className="bg-indigo-900 py-12 text-indigo-300 md:px-10 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:bg-transparent lg:px-0 lg:pb-24 lg:pt-0"
                >
                    <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                        <h2 id="summary-heading" className="sr-only">
                            Order summary
                        </h2>

                        <dl>
                            <dt className="text-sm font-medium">Order Summary</dt>
                        </dl>

                        <ul role="list" className="divide-y divide-white divide-opacity-10 text-sm font-medium">
                            {products.map((product, index) => (
                                <li key={index} className="flex items-start space-x-4 py-6">
                                    <img
                                        alt={product.photo}
                                        src={product.photo}
                                        className="h-20 w-20 flex-none rounded-md object-cover object-center"
                                    />
                                    <div className="flex-auto space-y-1">
                                        <h3 className="text-white">{product.name}</h3>
                                        <p>{product.selectedColor}</p>
                                        <p>{product.selectedSize}</p>
                                    </div>
                                    <p className="flex-none text-base font-medium text-white">${product.price}</p>
                                </li>
                            ))}
                        </ul>

                        <dl className="space-y-6 border-t border-white border-opacity-10 pt-6 text-sm font-medium">
                            <div className="flex items-center justify-between">
                                <dt>Subtotal</dt>
                                <dd>${subtotal}</dd>
                            </div>

                            <div className="flex items-center justify-between">
                                <dt>Shipping</dt>
                                <dd>${shippingTotal}</dd>
                            </div>

                            <div className="flex items-center justify-between">
                                <dt>Sales Tax</dt>
                                <dd>${salesTax}</dd>
                            </div>

                            <div className="flex items-center justify-between border-t border-white border-opacity-10 pt-6 text-white">
                                <dt className="text-base">Total</dt>
                                <dd className="text-base">${orderTotal}</dd>
                            </div>
                        </dl>
                    </div>
                    <div className="mt-6">
                        <button
                            className={`mt-8 flex w-full items-center justify-center rounded-md border border-transparent px-8 py-3 text-base font-medium bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                            onClick={() => {

                            }}
                        >
                            Place Order
                        </button>
                    </div>
                </section>

                <section
                    aria-labelledby="payment-and-shipping-heading"
                    className="py-16 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:pb-24 lg:pt-0"
                >
                    <h2 id="payment-and-shipping-heading" className="sr-only">
                        Payment and shipping details
                    </h2>

                    <form>
                        <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                            <div>
                                <h3 id="contact-info-heading" className="text-lg font-medium text-gray-900">
                                    Contact information
                                </h3>

                                <div className="mt-6">
                                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                        Email address
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="email-address"
                                            name="email-address"
                                            type="email"
                                            autoComplete="email"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-10">
                                <h3 className="text-lg font-medium text-gray-900">Shipping address</h3>

                                <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                            Address
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="address"
                                                name="address"
                                                type="text"
                                                autoComplete="street-address"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                            City
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="city"
                                                name="city"
                                                type="text"
                                                autoComplete="address-level2"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                                            State / Province
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="state"
                                                name="state"
                                                value={selectedState}
                                                onChange={e => setSelectedState(e.target.value)}
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            >
                                                <option value="">Select State</option>
                                                {states.map(state => (
                                                    <option key={state.code} value={state.code}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                            Country
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="country"
                                                name="country"
                                                value={selectedCountry}
                                                onChange={e => setSelectedCountry(e.target.value)}
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            >
                                                <option value="">Select Country</option>
                                                {countries.map(country => (
                                                    <option key={country.code} value={country.code}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                            Postal code
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="postal-code"
                                                name="postal-code"
                                                type="text"
                                                autoComplete="postal-code"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default Checkout;