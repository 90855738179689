import React, { createContext, useContext, useState, useEffect } from 'react';
import { config, getAccount, getAllDocuments, getCurrentUser } from '../lib/appwrite';
import { useLocation, useNavigate } from 'react-router-dom';

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [screenSize, setScreenSize] = useState(undefined)
    const [currentColor, setCurrentColor] = useState('#1E4DB7')
    const [currentMode, setCurrentMode] = useState('Light')
    const [themeSettings, setThemeSettings] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [allowedPages, setAllowedPages] = useState([])
    const [cartItems, setCartItems] = useState([])

    const [account, setAccount] = useState(null);

    useEffect(() => {
        let savedCart;
        try {
            savedCart = JSON.parse(localStorage.getItem('cart')) || [];
        } catch {
            savedCart = []
        }
        setCartItems(savedCart);
    }, [])

    useEffect(() => {
        const fetchAccount = async () => {
            try {
                const fetchedAccount = await getAccount();
                setAccount(fetchedAccount);
                setIsLoggedIn(true);
                setCurrentMode(localStorage.getItem("themeMode"));
                setCurrentColor(localStorage.getItem("colorMode"));
            } catch (error) {
                // console.error('Error fetching account:', error.message);
            }
        };

        fetchAccount(); // Call the fetchAccount function when the component mounts
    }, []);

    const fetchAllowedPages = async () => {
        const user = await getCurrentUser()

        let permissions;
        if (user && user.permissions && user.permissions === 'custom') {
            permissions = await getAllDocuments(config.permissionsCollectionId, null, [{ permissionName: user.accountId }])
        } else {
            permissions = await getAllDocuments(config.permissionsCollectionId, null, [{ permissionName: user.permissions }])
        }

        var paths = JSON.parse(permissions[0].pageAccess)

        paths = paths.map((path, index) => {
            return path.path
        })

        return paths
    }

    const setMode = (value) => {

        setCurrentMode(value)

        localStorage.setItem('themeMode', value)
        setThemeSettings(false)
    }

    const setColor = (color) => {
        setCurrentColor(color)

        localStorage.setItem('colorMode', color)
        setThemeSettings(false)
    }

    return (
        <StateContext.Provider
            value={{
                activeMenu,
                setActiveMenu,
                screenSize,
                setScreenSize,
                currentColor,
                setCurrentColor,
                currentMode,
                setCurrentMode,
                setColor,
                setMode,
                themeSettings,
                setThemeSettings,
                isLoggedIn,
                setIsLoggedIn,
                account,
                setAccount,
                allowedPages,
                fetchAllowedPages,
                cartItems,
                setCartItems
            }}
        >
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);