import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";

const url = 'https://api.teammvpbme.com'; // Fixed URL

// Component to render messages
function Message({ content }) {
  return <p>{content}</p>;
}

const PaypalCheckout = ({ price }) => {
  const navigate = useNavigate()

  // Sandbox
  // const initialOptions = {
  //   "client-id": "AYYqP3baxVyaaRhhGnnPslzTGaRVQAickEjvnTHkBvuo586KIZxkzTXspMO61BbUcrZaB0CUDsiB3c9h",
  //   "enable-funding": "venmo,card,paylater",
  //   "disable-funding": "",
  //   "data-sdk-integration-source": "integrationbuilder_sc",
  // };

  // Production
  const initialOptions = {
    "client-id": "AarMn-8gUReQsnM0NwrEUkwtLW2ULnystwavEb_lzdMncYNRlKQLDcVQ86ViXNzznnbxpATxtvUGIBT4",
    "enable-funding": "venmo,card,paylater",
    "disable-funding": "",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };

  const [message, setMessage] = useState("");


  return (
    <div className="PaypalCheckout">
      {price > 0 && (
        <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            style={{
              shape: "rect",
              layout: "vertical",
            }}
            createOrder={async () => {
              try {
                const response = await fetch(`${url}/api/orders`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ price }), // Send price in the request body
                });

                const orderData = await response.json();

                if (orderData.id) {
                  return orderData.id;
                } else {
                  const errorDetail = orderData?.details?.[0];
                  const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);

                  throw new Error(errorMessage);
                }
              } catch (error) {
                console.error(error);
                setMessage(`Could not initiate PayPal Checkout...${error}`);
              }
            }}
            onApprove={async (data, actions) => {
              try {
                const response = await fetch(
                  `${url}/api/orders/${data.orderID}/capture`, // Use url variable here
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );

                const orderData = await response.json();

                const errorDetail = orderData?.details?.[0];

                if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                  return actions.restart();
                } else if (errorDetail) {
                  throw new Error(
                    `${errorDetail.description} (${orderData.debug_id})`
                  );
                } else {
                  const transaction =
                    orderData.purchase_units[0].payments.captures[0];
                  setMessage(
                    `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                  );
                  console.log(
                    "Capture result",
                    orderData,
                    JSON.stringify(orderData, null, 2)
                  );

                  navigate(`/thank-you/${orderData.id}`)
                }
              } catch (error) {
                console.error(error);
                setMessage(
                  `Sorry, your transaction could not be processed...${error}`
                );
              }
            }}
          />
        </PayPalScriptProvider>
      )}
      <Message content={message} />
    </div>
  );
}

export default PaypalCheckout;
