import React, { useState } from 'react';
import Button from './Button';
import { CiImageOff } from "react-icons/ci";


const PhotoPicker = ({ onFileSelect, themeColor, submitError, previewSrc, setPreviewSrc, disabled, previewImage, setPreviewImage }) => {
    const [isLoading, setIsLoading] = useState(false)

    const handleFileChange = (e) => {
        setIsLoading(true)
        setPreviewImage('')
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewSrc(reader.result);
            };
            reader.readAsDataURL(file);
            onFileSelect(file);
        }
        setIsLoading(false)

        e.target.value = '';
    };

    return (
        <div className='text-center justify-center'>
            <input
                type="file"
                id="file-input"
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e)}
            />
            <div className={`w-[200px] h-[200px] rounded-xl ${submitError ? 'text-red-500 bg-red-900 bg-opacity-30' : ''} mx-auto mb-2`}>
                {previewImage ? (
                    <img src={previewImage} alt="Preview" className="w-full h-full object-cover rounded-xl" />
                ) : (
                    previewSrc ? (
                        <img src={previewSrc} alt="Preview" className="w-full h-full object-cover rounded-xl" />
                    ) : (
                        <div className={`flex flex-col justify-center items-center text-center my-auto h-full border-[3px] ${submitError ? 'border-red-900' : 'border-white'} opacity-30 rounded-xl`}>
                            <CiImageOff className="text-[100px] mx-auto" />
                            <p>No photo selected</p>
                        </div>
                    )
                )}

            </div>
            <div
                onClick={() => document.getElementById('file-input').click()}
                className={`${disabled ? 'hidden' : 'block'}`}
            >
                <Button
                    bgColor={`${submitError ? '#EF4444' : themeColor}`}
                    text="Select Photo"
                    size="md"
                    borderRadius={"12px"}
                    otherStyles="w-[200px] items-center text-center font-semibold text-white"
                    isLoading={isLoading}
                />
            </div>

        </div>
    );
};

export default PhotoPicker;
