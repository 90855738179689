import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const Button = ({ bgColor, color, size, text, borderRadius, otherStyles, isLoading }) => {
  return (
    <button
      type='button'
      style={{ backgroundColor: bgColor, color, borderRadius, opacity: isLoading ? 0.7 : 1 }}
      className={`text-${size} p-3 hover:drop-shadow-xl ${otherStyles}`}
      disabled={isLoading}
    >
      <div className='flex align-middle justify-center items-center space-x-2'>
        <div>{text}</div>
        {isLoading && (
          <FaSpinner className="animate-spin" />
        )}
      </div>
    </button>
  );
};

export default Button;