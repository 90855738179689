import React, { useEffect, useState, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useStateContext } from '../../../contexts/ContextProvider';
import EditableTable from '../../../components/EditableTable';
import { Button } from '../../../components';
import FormField from '../../../components/FormField';
import { updateDocument, config, uploadData, uploadFile } from '../../../lib/appwrite';
import DateInput from '../../../components/DateField';
import DropdownField from '../../../components/DropdownField';
import MessageField from '../../../components/MessageField';
import PhotoPicker from '../../../components/PhotoPicker';

const EditPhoto = ({ view, setView, selectedRowData, setSelectedRowData, mode }) => {
    const { currentColor, account } = useStateContext();

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [slogan, setSlogan] = useState('')
    const [twitterHandle, setTwitterHandle] = useState('')
    const [facebookHandle, setFacebookHandle] = useState('')
    const [instagramHandle, setInstagramHandle] = useState('')
    const [tiktokHandle, setTiktokHandle] = useState('')
    const [linkedinHandle, setLinkedinHandle] = useState('')

    const [file, setFile] = useState(null);
    const [previewSrc, setPreviewSrc] = useState('');
    const [fileError, setFileError] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const prefillData = async () => {
            if (view && selectedRowData) {
                setName(selectedRowData.name)
                setPreviewImage(selectedRowData.photoUrl)
            }
        }

        prefillData();
    }, [view]);

    const close_menu = () => {
        setName('')
        setFile(null)
        setPreviewSrc(null)
        setSelectedRowData(null);
        setView(false);
        setIsLoading(false);
    };

    const submit = async () => {
        if (name === '') { setNameError(true) } else { setNameError(false) }
        if (!file) { setFileError(true) } else { setFileError(false) }
        if (
            name === '' ||
            !file
        ) { return }

        if (file) {
            try {
                setIsLoading(true)
                const url = await uploadFile(file, config.photosBucketId);

                const data = {
                    name: name,
                    photo: url,
                    slogan: slogan,
                    twitter: twitterHandle,
                    facebook: facebookHandle,
                    instagram: instagramHandle,
                    tiktok: tiktokHandle,
                    linkedin: linkedinHandle
                }

                const response = await uploadData(data, config.leadershipCollectionId)

                close_menu()
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }
    }

    const handleFileSelect = (selectedFile) => {
        setFile(selectedFile);
    };

    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0'>
            <div className='float-right h-screen overflow-scroll dark:text-gray-200 bg-white dark:bg-secondary-dark-bg w-screen md:w-[600px]'>
                <div className='flex justify-between items-center p-4 ml-4'>
                    {mode === 'edit' && (
                        <p className='font-semibold text-xl'>Edit Leader</p>
                    )}
                    {mode === 'create' && (
                        <p className='font-semibold text-xl'>Create Leader</p>
                    )}
                    {mode === 'view' && (
                        <p className='font-semibold text-xl'>Leader</p>
                    )}
                    <button
                        type='button'
                        onClick={() => close_menu()}
                        style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                    >
                        <MdOutlineCancel />
                    </button>
                </div>

                <div className='flex-col border-t-1 border-color p-4 ml-4 space-y-6'>
                    <FormField
                        title="Name"
                        placeholder="Name"
                        value={name}
                        handleChangeText={(e) => setName(e)}
                        error={nameError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <PhotoPicker
                        onFileSelect={handleFileSelect}
                        themeColor={currentColor}
                        submitError={fileError}
                        previewSrc={previewSrc}
                        setPreviewSrc={setPreviewSrc}
                        disabled={mode === 'view'}
                        previewImage={previewImage}
                        setPreviewImage={setPreviewImage}
                    />

                    <FormField
                        title="Slogan"
                        placeholder="Slogan"
                        value={slogan}
                        handleChangeText={(e) => setSlogan(e)}
                        disabled={mode === 'view'}
                    />

                    <FormField
                        title="X Handle"
                        placeholder="@account_name"
                        value={twitterHandle}
                        handleChangeText={(e) => setTwitterHandle(e)}
                        disabled={mode === 'view'}
                    />

                    <FormField
                        title="Instagram"
                        placeholder="@account_name"
                        value={instagramHandle}
                        handleChangeText={(e) => setInstagramHandle(e)}
                        disabled={mode === 'view'}
                    />

                    <FormField
                        title="Tiktok Handle"
                        placeholder="@account_name"
                        value={tiktokHandle}
                        handleChangeText={(e) => setTiktokHandle(e)}
                        disabled={mode === 'view'}
                    />

                    <FormField
                        title="Linkedin Handle"
                        placeholder="@account_name"
                        value={linkedinHandle}
                        handleChangeText={(e) => setLinkedinHandle(e)}
                        disabled={mode === 'view'}
                    />

                    <div
                        className={`${mode === 'view' ? 'hidden' : 'block'}`}
                        onClick={() => {
                            if (!isLoading) {
                                setTimeout(submit, 100)
                            }
                        }}
                    >
                        <Button
                            bgColor={currentColor}
                            text="Submit"
                            size="md"
                            borderRadius={"12px"}
                            otherStyles="w-full items-center text-center font-semibold text-white"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EditPhoto;