import React, { useEffect } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok, FaTwitter } from 'react-icons/fa6';
import Button from './Button';
import noPhoto from '../assets/noPhoto.png'

const MerchandiseCard = ({ item, onClick }) => {

    return (
        <div
            className="group relative p-4 sm:p-6"
            onClick={onClick}
        >
            <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-200 group-hover:opacity-75">
                {item.photos && (
                    <img src={item.photos[0] || noPhoto} alt="TODO" className="h-full w-full object-cover object-center" />
                )}
            </div>
            <div className="pb-4 pt-10 text-center">
                <h3 className="text-sm font-medium text-gray-900">
                    <a href="#">
                        <span aria-hidden="true" className="absolute inset-0"></span>
                        {item.name}
                    </a>
                </h3>
                <div className="mt-3 flex flex-col items-center">
                    {item.reviews && (
                        <div>
                            <p className="sr-only">{item.stars} out of 5 stars</p>
                            <div className="flex items-center">
                                {[...Array(item.stars)].map((_, i) => (
                                    <svg key={i} className="h-5 w-5 flex-shrink-0 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd" />
                                    </svg>
                                ))}
                            </div>
                        </div>
                    )}
                    {item.stars && (
                        <p className="mt-1 text-sm text-gray-500">{item.reviews} reviews</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MerchandiseCard;
