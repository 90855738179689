import React, { useEffect, useState } from 'react';
// import '../../css/Home.css';
import hero_bg_lg from '../../assets/hero-bg-lg.png';
import hero_bg_sm from '../../assets/hero-bg-sm.png'
import hero_bg from '../../assets/hero-bg.png'

import { LuHeartHandshake } from "react-icons/lu";
import { MdOutlineFamilyRestroom } from "react-icons/md";
import { GiBrain } from "react-icons/gi";
import { FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaRegHandshake, FaSkype, FaTwitter } from "react-icons/fa6";
import { IoPawOutline } from "react-icons/io5";
import { FaRegSmile } from "react-icons/fa";
import { BsHouseDoor } from "react-icons/bs";
import { BiSolidBinoculars } from "react-icons/bi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaDog } from "react-icons/fa6";
import { FaTaxi } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import PortfolioCard from '../../components/PortfolioCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { BsEnvelope } from "react-icons/bs";
import { CiMap } from "react-icons/ci";
import { MdOutlinePhone } from "react-icons/md";
import MessageField from '../../components/MessageField'
import FormField from '../../components/FormField'
import Button from '../../components/Button'
import noPhoto from '../../assets/noPhoto.png'
import colors from '../../assets/colors';
import { SimpleMessageField } from '../../components'

import 'swiper/css';
import 'swiper/css/pagination';
import Testimonial from '../../components/Testimonial';
import useAppwrite from '../../lib/useAppwrite';
import { config, getAllDocuments, uploadData } from '../../lib/appwrite';
import TestimonialSwiper from '../../components/TestimonialSwiper';
import TeamAllianceCard from '../../components/TeamAllianceCard';
import LeadershipCard from '../../components/LeadershipCard';
import { useStateContext } from '../../contexts/ContextProvider';
import MerchandiseCard from '../../components/MerchandiseCard'
import { useNavigate } from 'react-router-dom';


const Home = () => {
    const navigate = useNavigate()
    const { cartItems, setCartItems } = useStateContext()
    const [activePortfolioFilter, setActivePortfolioFilter] = useState('All')
    const [errorMessage, setErrorMessage] = useState('')
    const [sentMessage, setSentMessage] = useState(false)

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [subject, setSubject] = useState('')
    const [subjectError, setSubjectError] = useState(false)
    const [message, setMessage] = useState('')
    const [messageError, setMessageError] = useState(false)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const { data: leadership } = useAppwrite(
        () => getAllDocuments(config.leadershipCollectionId)
    );

    const { data: merch } = useAppwrite(
        () => getAllDocuments(config.merchandiseCollectionId)
    );

    const { data: teamAlliance } = useAppwrite(
        () => getAllDocuments(config.teamCollectionId)
    );

    useEffect(() => {
        if (sentMessage) {
            const timer = setTimeout(() => {
                setSentMessage(false);
                setName('')
                setEmail('')
                setSubject('')
                setMessage('')
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [sentMessage]);

    const submitMessage = async () => {
        if (name === '') { setNameError(true) } else { setNameError(false) }
        if (email === '') { setEmailError(true) } else { setEmailError(false) }
        if (subject === '') { setSubjectError(true) } else { setSubjectError(false) }
        if (message === '') { setMessageError(true) } else { setMessageError(false) }

        if (
            name === '' ||
            email === '' ||
            subject === '' ||
            message === ''
        ) { return }

        try {
            setIsSubmitting(true)

            const data = {
                name: name,
                email: email,
                subject: subject,
                message: message
            }

            const response = await uploadData(data, config.messagesCollectionId)
            setSentMessage(true)
        } catch (error) {
            console.error(error)
            setNameError(false)
            setEmailError(false)
            setSubjectError(false)
            setMessageError(false)
        } finally {
            setIsSubmitting(false)
        }
    }

    const [showProductCard, setShowProductCard] = useState(false);

    return (
        <div id='home'>
            {/* Hero Section */}
            <div className="relative flex flex-col lg:flex-row items-center justify-center h-screen mx-[15%]">
                {/* Content on the left */}
                <div className="w-full lg:w-1/2 z-10">
                    <h1 className="m-0 text-[48px] font-bold text-[#111]">Love to all! We are here to Bless!</h1>
                    {/* <h2>second tagline</h2> */}
                    <a
                        href="#about"
                        className="font-medium text-base lg:text-xl inline-block py-3 lg:py-4 px-6 lg:px-8 rounded-md transition-all duration-500 mt-5 lg:mt-8 text-white bg-[#000081] hover:bg-[#a97c29] hover:border-white"
                    >
                        Learn More
                    </a>
                </div>

                {/* Logo on the right */}
                <div className="absolute lg:static right-0 transform z-0">
                    <img src={hero_bg} alt="Logo" className="max-w-full h-auto" />
                </div>
            </div>

            {/* About Section */}
            <div className="relative">
                <div className="absolute inset-0 bg-[#1b1b1b] transform -skew-y-3"></div>
                <section id="about" className="py-32 text-white mx-[15%]">
                    <div className="relative z-10 mx-auto">
                        <div className="grid grid-cols-3 md:grid-cols-2 space-x-6">
                            <div className="col-span-3 md:col-span-1">
                                <div className="mt-6 md:mt-0 mr-6 md:mr-0 mb-6 md:mb-0 ml-0">
                                    <h3 className='font-bold text-2xl md:text-3xl mb-6 md:mb-8'>About Us</h3>
                                    <p className='mb-6 md:mb-8'>
                                        Team MVPBME is a family of strong and talented creators banding together to bring exciting
                                        content to TikTok. Our team shows support to all our members whether in daily battles, big
                                        battles or box battles. We are a drama free team focusing on the success of our members. Join
                                        our team/family and enjoy all the support from Team MVPBME!
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>

            {/* <!-- ======= Leadership Section ======= --> */}
            <div id='leadership' className=''>
                <div className='px-[15%] p-[60px]'>
                    <div className='text-center pb-[30px]'>
                        <h2 className='text-[32px] font-bold uppercase mb-[20px] pb-0 text-[#0f394c]'>Leadership</h2>
                        <p className='mb-0'></p>
                    </div>

                    <div className='flex justify-center'>
                        <div className={`grid gap-4 sm:grid-cols-1 ${leadership.length === 1 ? 'md:grid-cols-1' :
                            leadership.length === 2 ? 'md:grid-cols-2' :
                                leadership.length === 3 ? 'md:grid-cols-3' :
                                    leadership.length === 4 ? 'md:grid-cols-4' :
                                        'md:grid-cols-2 lg:grid-cols-4'}`}>
                            {leadership.map((card, index) => (
                                <LeadershipCard key={index} card={card} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Merch Section */}
            <div className="relative" id="merch">
                <div className="absolute inset-0 bg-[#1b1b1b] transform -skew-y-3"></div>
                <section className="py-32 text-white mx-[15%]">
                    <div className="mx-auto" data-aos="fade-up">
                        <div className="text-center pb-[30px] relative">
                            <div className="text-[32px] font-bold uppercase mb-5 pb-5 relative">
                                <h2>Merchandise</h2>
                                <span className="block w-14 h-1 bg-[#a97c29] absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                            </div>
                            <p className='mb-0'></p>
                        </div>
                        <div className="-mx-px grid grid-cols-2 sm:mx-0 md:grid-cols-3 lg:grid-cols-4 gap-5">
                            {merch.length > 0 && merch.map((product, index) => (
                                <div
                                    key={index}
                                    className="relative bg-gray-100 rounded-xl shadow-md group hover:bg-gray-200"
                                >
                                    <MerchandiseCard
                                        item={product}
                                        onClick={() => {
                                            navigate(`/products/${product.$id}`);
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                    </div>
                </section>
            </div>

            {/* <!-- ======= Charitable Giving Section ======= --> */}
            <div id='giving' className=''>
                <div className='px-[15%] p-[60px]'>
                    <div className="grid grid-cols-3 md:grid-cols-2">
                        <div className="col-span-3 md:col-span-1">
                            <div className="mt-6 md:mt-0 mr-6 md:mr-0 mb-6 md:mb-0 ml-0">
                                <h3 className='font-bold text-2xl md:text-3xl mb-6 md:mb-8'>Charitable Giving</h3>
                                <p className='mb-6 md:mb-8'>
                                    MVPBME Foundation was created to help so many from the Alzheimer's association to fellow
                                    tiktok creators struggling with health issues. We compete in battles to support those in need.
                                    Ways our foundation is supported; TikTok subscriptions, Big battles, Donations, Merch, and
                                    Ads. Please visit our website for more information on how to get involved.
                                </p>
                            </div>
                        </div>
                        {/* <div className="col-span-3 md:col-span-1">
                                <div className="icon-boxes grid md:grid-cols-2 sm:grid-cols-1 gap-4">
                                    <div className="mt-6 md:mt-0" data-aos="fade-up" data-aos-delay="100">
                                        <i className='text-4xl text-[#000081] mb-6'><MdOutlineFamilyRestroom /></i>
                                        <h4 className='text-lg font-bold m-0 mb-4'></h4>
                                        <p className='text-sm text-[#848484]'></p>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>

            {/* TikTok Team Alliance Section */}
            <div className="relative" id="team">
                <div className="absolute inset-0 bg-[#1b1b1b] transform -skew-y-3"></div>
                <section className="py-32 text-white mx-[15%]">
                    <div className="mx-auto" data-aos="fade-up">
                        <div className="text-center pb-[30px] relative">
                            <div className="text-[32px] font-bold uppercase mb-5 pb-5 relative">
                                <h2>TikTok Team Alliance</h2>
                                <span className="block w-14 h-1 bg-[#a97c29] absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                            </div>
                            <p className='mb-0'>The alliance works together to support every member in their box battles and big battles. The more support you have the bigger the success.</p>
                        </div>
                        <div className='flex justify-center'>
                            <div className={`grid gap-4 sm:grid-cols-1 ${teamAlliance.length === 1 ? 'md:grid-cols-1' :
                                teamAlliance.length === 2 ? 'md:grid-cols-2' :
                                    teamAlliance.length === 3 ? 'md:grid-cols-3' :
                                        teamAlliance.length === 4 ? 'md:grid-cols-4' :
                                            'md:grid-cols-2 lg:grid-cols-4'}`}>
                                {teamAlliance.map((team, index) => (
                                    <TeamAllianceCard key={index} team={team} />
                                ))}
                            </div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-10'>

                        </div>
                    </div>
                </section>
            </div>

            {/* Contact Section */}
            <section id="contact" className="mx-[15%]">
                <div className="mx-auto" data-aos="fade-up">
                    <div className="text-center pb-[30px] relative">
                        <div className="text-[32px] font-bold uppercase mb-5 pb-5 relative">
                            <h2>Contact</h2>
                            <span className="block w-14 h-1 bg-[#a97c29] absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                        </div>
                        <p className='mb-0'>Interested in Joining the alliance please visit the alliance website (Here)</p>
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-1/2 p-4">
                            <div className="mb-4 text-[#444444] text-center shadow-xl p-0 pt-[30px] pb-[32px] rounded-md">
                                <FaEnvelope
                                    className="rounded-full text-[32px] p-1 text-[#000081] border-dotted border-2 border-[#CCCCFF] mx-auto"
                                />
                                <h3 className="text-[20px] text-[#777777] font-[700] my-[10px] mx-0">Reach out to us!</h3>
                                <p className="p-0 text-[14px] mb-0">info@teammvpbme.com</p>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 p-4">
                            <div className="text-[14px] shadow-xl p-[30px] pb-[32px] rounded-md">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <FormField
                                        value={name}
                                        placeholder={"Your Name"}
                                        handleChangeText={(e) => setName(e)}
                                        error={nameError}
                                    />
                                    <FormField
                                        value={email}
                                        placeholder={"Your Email"}
                                        handleChangeText={(e) => setEmail(e)}
                                        error={emailError}
                                    />
                                </div>
                                <FormField
                                    value={subject}
                                    placeholder={"Subject"}
                                    handleChangeText={(e) => setSubject(e)}
                                    error={subjectError}
                                />
                                <SimpleMessageField
                                    value={message}
                                    placeholder={"Message"}
                                    handleChangeText={(e) => (setMessage(e))}
                                    error={messageError}
                                />
                                <div className="my-3">
                                    <div className={`${errorMessage === '' ? 'hidden' : 'block'}`}>{errorMessage}</div>
                                    <div className={`${sentMessage === false ? 'hidden' : 'block'} text-center text-green-600`}>Your message has been sent. Thank you!</div>
                                </div>
                                <div className="text-center" onClick={() => submitMessage()}>
                                    <Button
                                        bgColor={"#000081"}
                                        color={"white"}
                                        text={"Send Message"}
                                        borderRadius={"10px"}
                                        isLoading={isSubmitting}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;
