// const url = 'http://localhost:8888'; // Fixed URL
const url = 'https://api.teammvpbme.com'

export const fetchOrderDetails = async (orderId) => {
    try {
        const response = await fetch(`${url}/show-order-details?orderId=${orderId}`);
        const contentType = response.headers.get("content-type");

        if (!response.ok) {
            throw new Error('Failed to fetch order details');
        }

        if (contentType && contentType.indexOf("application/json") !== -1) {
            // If the response is JSON, parse it
            const data = await response.json();
            return data;
        } else {
            // If not JSON, it might be an HTML error page or something else
            const text = await response.text();
            throw new Error(`Unexpected response format: ${text}`);
        }
    } catch (error) {
        console.error('Error fetching order details:', error);
        return null;
    }
};

export const fetchOrderSummary = async (items) => {
    try {
        const response = await fetch(`${url}/calculate-order-summary`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ items }),
        });

        if (!response.ok) {
            throw new Error('Failed to calculate order summary');
        }

        const data = await response.json();
        console.log(data)
        return data
    } catch (error) {
        return null
        console.error('Error fetching order summary:', error);
    }
};
