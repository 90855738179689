import React, { useEffect, useState, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useStateContext } from '../../contexts/ContextProvider';
import { Button, PhotoPicker, FormField, EditableTable, ComplexTable } from '../../components';
import { updateDocument, config, uploadData, uploadFile } from '../../lib/appwrite';

const EditVariants = ({ view, setView, variants, setVariants, mode }) => {
    const { currentColor, account } = useStateContext();

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // const prefillData = async () => {
        //     if (view && selectedRowData) {
        //         setName(selectedRowData.name)
        //         setPreviewImage(selectedRowData.photoUrl)
        //     }
        // }

        // prefillData();
    }, [view]);

    const submit = async () => {
        setView(false)
    }

    const handleFileSelect = (selectedFile) => {
        setFile(selectedFile);
    };

    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0'>
            <div className='float-right h-screen overflow-scroll dark:text-gray-200 bg-white dark:bg-secondary-dark-bg w-screen md:w-[1000px]'>
                <div className='flex justify-between items-center p-4 ml-4'>
                    {mode === 'edit' && (
                        <p className='font-semibold text-xl'>Edit Variants</p>
                    )}
                    {mode === 'create' && (
                        <p className='font-semibold text-xl'>Create Variants</p>
                    )}
                    {mode === 'view' && (
                        <p className='font-semibold text-xl'>Variants</p>
                    )}
                    <button
                        type='button'
                        onClick={() => submit()}
                        style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                    >
                        <MdOutlineCancel />
                    </button>
                </div>

                <div className='flex-col border-t-1 border-color p-4 ml-4 space-y-6'>
                    <EditableTable
                        title="Sizes"
                        data={variants}
                        setData={setVariants}
                        rowActions={['Edit', 'Delete']}
                        addRow={true}
                        keyMapping={{
                            'Size': 'size',
                            'Color': 'color',
                            'Color Name': 'colorName',
                            'Price': 'price',
                            'Cost': 'cost',
                            'Stock': 'stock'
                        }}
                    />

                    <div
                        className={`${mode === 'view' ? 'hidden' : 'block'}`}
                        onClick={() => {
                            if (!isLoading) {
                                setTimeout(submit, 100)
                            }
                        }}
                    >
                        <Button
                            bgColor={currentColor}
                            text="Save"
                            size="md"
                            borderRadius={"12px"}
                            otherStyles="w-full items-center text-center font-semibold text-white"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EditVariants;