import React, { useState } from 'react';

const DateField = ({ id, title, value, placeholder, handleChangeText, otherStyles, error, required }) => {
  // // Function to format the date string to yyyy-mm-dd format
  // const formatDate = (dateString) => {
  //   // Assuming dateString is in mm/dd/yyyy format
  //   const [month, day, year] = dateString.split('/');
  //   return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  // };

  // // Format the value if it's not in yyyy-mm-dd format
  // const formattedValue = value.includes('/') ? formatDate(value) : value;

  return (
    <div className={`space-y-2 ${otherStyles}`}>
      <div className='flex'>
        <p className="text-base dark:text-gray-100 font-medium">{title}</p>
        <p className={`text-red-500 font-medium pl-2 ${required ? "block" : "hidden"}`}>*</p>
      </div>

      <div className={`border-2 border-black-200 bg-black-100 focus:border-secondary flex items-center justify-between rounded-2xl ${error ? "bg-red-500 bg-opacity-30 border-red-400 text-red-400" : "dark:text-white"}`}>
        <input
          id={id}
          className="flex-1 w-full h-10 px-4 rounded-2xl font-semibold text-base bg-transparent placeholder-gray-500"
          value={value}
          placeholder={placeholder}
          onChange={(e) => handleChangeText(e.target.value)}
          type="date" // This sets the input to a date picker
        />
      </div>
    </div>
  );
};



export default DateField;
