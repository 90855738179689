import React, { useState, useEffect, useRef } from 'react';

const DropdownField = ({ selected, setSelected, title, options, handleChangeText, required, error, searchable = false }) => {
  const [inputValue, setInputValue] = useState(selected);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (searchable) {
      setFilteredOptions(options.filter(option => option.toLowerCase().includes(inputValue.toLowerCase())));
    } else {
      setFilteredOptions(options);
    }
  }, [inputValue, options, searchable]);

  useEffect(() => {
    setInputValue(selected);
  }, [selected]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    handleChangeText(e.target.value);
  };

  const handleOptionSelect = (option) => {
    setInputValue(option);
    setSelected(option);
    handleChangeText(option);
    setFilteredOptions([]);
    setIsFocused(false);
  };

  const handleBlur = () => {
    if (!options.includes(inputValue)) {
      setInputValue('');
      handleChangeText('');
    }
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <div className='space-y-2'>
      <div className='flex'>
        <p className="text-base font-medium">{title}</p>
        <p className={`text-red-500 font-medium pl-2 ${required ? "block" : "hidden"}`}>*</p>
      </div>
      <div className={`relative border-2 border-black-200 bg-black-100 focus-within:border-secondary rounded-2xl ${error ? "bg-red-500 bg-opacity-30 border-red-400 text-red-400" : "dark:text-white"}`}>
        {searchable ? (
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className='flex-1 w-full h-10 px-4 rounded-2xl font-semibold text-base bg-transparent placeholder-gray-500'
            ref={inputRef}
          />
        ) : (
          <select
            value={selected}
            onChange={(e) => handleOptionSelect(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className='flex-1 w-full h-10 px-4 rounded-2xl font-semibold text-base bg-transparent placeholder-gray-500'
          >
            <option value=""></option>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
        {isFocused && filteredOptions.length > 0 && searchable && (
          <div className="absolute z-10 bg-white dark:bg-main-dark-bg border border-gray-300 dark:border-gray-700 rounded-lg mt-1 w-full max-h-60 overflow-y-auto">
            {filteredOptions.map((option, index) => (
              <div
                key={index}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-secondary-dark-bg"
                onMouseDown={() => handleOptionSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownField;
