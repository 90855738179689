import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaRegEdit, FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import { FaTrashCan } from 'react-icons/fa6';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const ComplexTable = ({
    title,
    data,
    setData,
    rowActions,
    addRow,
    keyMapping,
    onRowClick,
    reset,
    setReset,
    selectable,
    rowsPerPage = 10,
}) => {
    const [isAdding, setIsAdding] = useState(false);
    const [newTest, setNewTest] = useState({});
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOrder, setSortOrder] = useState({});
    const [filter, setFilter] = useState({});
    const [showDropdowns, setShowDropdowns] = useState({});
    const [filteredOptions, setFilteredOptions] = useState({});

    useEffect(() => {
        if (reset) {
            setSelectedRowIndex(null);
            setReset(false);
        }
    }, [reset]);

    useEffect(() => {
        // Initialize filteredOptions with all options for each dropdown
        const initialFilteredOptions = {};
        Object.keys(keyMapping).forEach(columnName => {
            if (keyMapping[columnName].fieldType === 'dropdown') {
                initialFilteredOptions[columnName] = keyMapping[columnName].options;
            }
        });
        setFilteredOptions(initialFilteredOptions);
    }, [keyMapping]);

    useEffect(() => {
        // Update input field when data changes
        if (!isAdding) {
            setNewTest({});
        }
    }, [data]);

    const handleRowClick = (rowData, index) => {
        if (selectable) {
            if (selectedRowIndex === index) {
                setSelectedRowIndex(null);
                if (onRowClick) {
                    onRowClick(null);
                }
            } else {
                setSelectedRowIndex(index);
                if (onRowClick) {
                    onRowClick(rowData);
                }
            }
        }
    };

    const handleAddClick = () => {
        setIsAdding(true);
    };

    const handleDeleteClick = (index) => {
        const updatedData = [...data];
        updatedData.splice(index, 1);
        setData(updatedData);
    };

    const handleSaveClick = () => {
        const newData = {};
        Object.keys(newTest).forEach((columnName) => {
            newData[keyMapping[columnName].key] = newTest[columnName];
        });

        setData([newData, ...data]);
        setNewTest({});
        setIsAdding(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTest({ ...newTest, [name]: value });
    };

    const handleDropdownChange = (columnName, option) => {
        setNewTest({ ...newTest, [columnName]: option });
        setShowDropdowns(prev => ({ ...prev, [columnName]: false }));
    };

    const handleDropdownInput = (e, columnName) => {
        const value = e.target.value;
        setNewTest({ ...newTest, [columnName]: value });

        if (keyMapping[columnName].fieldType === 'dropdown') {
            const options = keyMapping[columnName].options;
            if (value === '') {
                setFilteredOptions(prev => ({ ...prev, [columnName]: options }));
            } else {
                const filtered = options.filter(option =>
                    option.toLowerCase().includes(value.toLowerCase())
                );
                setFilteredOptions(prev => ({ ...prev, [columnName]: filtered }));
            }
        }
    };

    const handleEditClick = (index) => {
        const updatedData = [...data];
        updatedData[index].isEditing = true;
        setData(updatedData);
    };

    const handleEditChange = (e, index, columnName) => {
        const { value } = e.target;
        const updatedData = [...data];
        updatedData[index][keyMapping[columnName].key] = value;
        setData(updatedData);
    };

    const handleEditSave = (index) => {
        const updatedData = [...data];
        updatedData[index].isEditing = false;
        setData(updatedData);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSort = (columnName) => {
        const newSortOrder = {};
        newSortOrder[columnName] = sortOrder[columnName] === 'asc' ? 'desc' : 'asc';

        setSortOrder(newSortOrder);

        const sortedData = [...data].sort((a, b) => {
            const key = keyMapping[columnName].key;
            const aValue = a[key];
            const bValue = b[key];

            const aNumericValue = parseFloat(aValue);
            const bNumericValue = parseFloat(bValue);

            if (!isNaN(aNumericValue) && !isNaN(bNumericValue)) {
                return newSortOrder[columnName] === 'asc' ? aNumericValue - bNumericValue : bNumericValue - aNumericValue;
            }

            return newSortOrder[columnName] === 'asc' ? aValue.localeCompare(bValue, 'en', { sensitivity: 'base' }) : bValue.localeCompare(aValue, 'en', { sensitivity: 'base' });
        });

        setData(sortedData);
    };

    const handleFilterChange = (e, columnName) => {
        const value = e.target.value.toLowerCase();
        setFilter({ ...filter, [columnName]: value });
    };

    const filteredData = data.filter(item =>
        Object.keys(filter).every(columnName =>
            item[keyMapping[columnName].key].toString().toLowerCase().includes(filter[columnName])
        )
    );

    const startRow = (currentPage - 1) * rowsPerPage;
    const endRow = startRow + rowsPerPage;
    const paginatedData = filteredData.slice(startRow, endRow);

    return (
        <div>
            {addRow && (
                <div className="w-full flex">
                    <p className='font-semibold text-xl'>{title}</p>
                    <button
                        type="button"
                        className="ml-auto block rounded-md bg-indigo-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        onClick={isAdding ? handleSaveClick : handleAddClick}
                    >
                        {isAdding ? 'Save' : 'Add'}
                    </button>
                </div>
            )}
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-700">
                            <thead>
                                <tr>
                                    {Object.keys(keyMapping).map((columnName, index) => (
                                        <th key={index} scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold dark:text-white sm:pl-0 text-center">
                                            <button onClick={() => handleSort(columnName)}>
                                                {keyMapping[columnName].header} {sortOrder[columnName] === 'asc' ? '↑' : sortOrder[columnName] === 'desc' ? '↓' : ''}
                                            </button>
                                            <input
                                                type="text"
                                                value={filter[columnName] || ''}
                                                onChange={(e) => handleFilterChange(e, columnName)}
                                                className="w-full dark:bg-gray-800 bg-gray-200 dark:text-white text-black p-1 mt-1 rounded"
                                                placeholder={`Filter ${keyMapping[columnName].header}`}
                                            />
                                        </th>
                                    ))}
                                    {rowActions.length !== 0 && (
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                            Actions
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-300 dark:divide-gray-800 h-[600px] align-top">
                                {isAdding && (
                                    <tr>
                                        {Object.keys(keyMapping).map((columnName, index) => (
                                            <td key={index} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-white sm:pl-0">
                                                {keyMapping[columnName].fieldType === 'dropdown' ? (
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            name={columnName}
                                                            value={newTest[columnName] || ''}
                                                            onChange={(e) => handleDropdownInput(e, columnName)}
                                                            className="w-full dark:bg-gray-800 bg-gray-200 dark:text-white text-black p-1 rounded"
                                                            placeholder={`Select ${keyMapping[columnName].header}`}
                                                            onFocus={() => setShowDropdowns(prev => ({ ...prev, [columnName]: true }))}
                                                            onBlur={() => setTimeout(() => setShowDropdowns(prev => ({ ...prev, [columnName]: false })), 200)}
                                                        />
                                                        {showDropdowns[columnName] && (
                                                            <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-lg max-h-60 overflow-y-auto">
                                                                {filteredOptions[columnName] && filteredOptions[columnName].map((option, idx) => (
                                                                    <div
                                                                        key={idx}
                                                                        className="cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 p-2"
                                                                        onMouseDown={() => handleDropdownChange(columnName, option)}
                                                                    >
                                                                        {option}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        name={columnName}
                                                        value={newTest[columnName] || ''}
                                                        onChange={handleInputChange}
                                                        className="w-full dark:bg-gray-800 bg-gray-200 dark:text-white text-black p-1 rounded"
                                                        placeholder={`Enter ${keyMapping[columnName].header}`}
                                                    />
                                                )}
                                            </td>
                                        ))}
                                        {rowActions.length !== 0 && (
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                    onClick={() => setIsAdding(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                )}
                                {paginatedData.map((rowData, rowIndex) => (
                                    <tr
                                        key={rowIndex}
                                        className={`hover:bg-gray-50 dark:hover:bg-gray-600 ${selectedRowIndex === rowIndex ? 'bg-gray-200 dark:bg-gray-700' : ''}`}
                                        onClick={() => handleRowClick(rowData, rowIndex)}
                                    >
                                        {Object.keys(keyMapping).map((columnName, colIndex) => (
                                            <td key={colIndex} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-white sm:pl-0">
                                                {rowData.isEditing ? (
                                                    keyMapping[columnName].fieldType === 'dropdown' ? (
                                                        <select
                                                            value={rowData[keyMapping[columnName].key]}
                                                            onChange={(e) => handleEditChange(e, rowIndex, columnName)}
                                                            className="w-full dark:bg-gray-800 bg-gray-200 dark:text-white text-black p-1 rounded"
                                                        >
                                                            {keyMapping[columnName].options.map((option, idx) => (
                                                                <option key={idx} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            value={rowData[keyMapping[columnName].key]}
                                                            onChange={(e) => handleEditChange(e, rowIndex, columnName)}
                                                            className="w-full dark:bg-gray-800 bg-gray-200 dark:text-white text-black p-1 rounded"
                                                        />
                                                    )
                                                ) : (
                                                    rowData[keyMapping[columnName].key]
                                                )}
                                            </td>
                                        ))}
                                        {rowActions.length !== 0 && (
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                {rowData.isEditing ? (
                                                    <button
                                                        type="button"
                                                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                        onClick={() => handleEditSave(rowIndex)}
                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <>
                                                        {rowActions.includes('Edit') && (
                                                            <button
                                                                type="button"
                                                                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
                                                                onClick={() => handleEditClick(rowIndex)}
                                                            >
                                                                <FaRegEdit />
                                                            </button>
                                                        )}
                                                        {rowActions.includes('Delete') && (
                                                            <button
                                                                type="button"
                                                                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                                onClick={() => handleDeleteClick(rowIndex)}
                                                            >
                                                                <FaTrashCan />
                                                            </button>
                                                        )}
                                                    </>
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="mt-4 flex justify-between items-center">
                <div>
                    Page {currentPage} of {Math.ceil(filteredData.length / rowsPerPage)}
                </div>
                <div className="flex space-x-1">
                    <button
                        type="button"
                        className="px-3 py-2 border border-gray-300 dark:border-gray-700 text-sm font-medium rounded-md shadow-sm text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <FaArrowAltCircleLeft />
                    </button>
                    <button
                        type="button"
                        className="px-3 py-2 border border-gray-300 dark:border-gray-700 text-sm font-medium rounded-md shadow-sm text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === Math.ceil(filteredData.length / rowsPerPage)}
                    >
                        <FaArrowAltCircleRight />
                    </button>
                </div>
            </div>
        </div>
    );
};

ComplexTable.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    setData: PropTypes.func.isRequired,
    rowActions: PropTypes.array,
    addRow: PropTypes.bool,
    keyMapping: PropTypes.object.isRequired,
    onRowClick: PropTypes.func,
    reset: PropTypes.bool,
    setReset: PropTypes.func,
    selectable: PropTypes.bool,
    rowsPerPage: PropTypes.number,
};

export default ComplexTable;
