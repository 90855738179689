import React, { useState } from 'react';

const ProductSetup = () => {
  const [product, setProduct] = useState({
    name: '',
    color: '',
    size: '',
    cost: '',
    price: '',
    salesPrice: '',
    photos: [],
    primaryPhoto: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handlePhotoUpload = (e) => {
    const files = Array.from(e.target.files);
    setProduct({ ...product, photos: files });
  };

  const handlePrimaryPhotoSelect = (index) => {
    setProduct({ ...product, primaryPhoto: product.photos[index] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="product-setup">
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Product Name:
            <input type="text" name="name" value={product.name} onChange={handleChange} />
          </label>
        </div>
        <div>
          <label>
            Color:
            <input type="text" name="color" value={product.color} onChange={handleChange} />
          </label>
        </div>
        <div>
          <label>
            Size:
            <input type="text" name="size" value={product.size} onChange={handleChange} />
          </label>
        </div>
        <div>
          <label>
            Cost:
            <input type="number" name="cost" value={product.cost} onChange={handleChange} />
          </label>
        </div>
        <div>
          <label>
            Price:
            <input type="number" name="price" value={product.price} onChange={handleChange} />
          </label>
        </div>
        <div>
          <label>
            Sales Price:
            <input type="number" name="salesPrice" value={product.salesPrice} onChange={handleChange} />
          </label>
        </div>
        <div>
          <label>
            Upload Photos:
            <input type="file" multiple onChange={handlePhotoUpload} />
          </label>
        </div>
        <div>
          {product.photos.length > 0 && (
            <>
              <h4>Select Primary Photo:</h4>
              {product.photos.map((photo, index) => (
                <div key={index}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt={`Photo ${index + 1}`}
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    onClick={() => handlePrimaryPhotoSelect(index)}
                  />
                  {product.primaryPhoto === photo && <span>Primary</span>}
                </div>
              ))}
            </>
          )}
        </div>
        <button type="submit">Save Product</button>
      </form>
    </div>
  );
};

export default ProductSetup;
