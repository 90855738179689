import React, { useEffect, useState } from 'react'
import { FaArrowLeft, FaImage, FaPlus, FaTrash } from 'react-icons/fa'
import { Button, FormField, Header, Input1, MessageField, ToggleSwitch, WhatIsThis } from '../../components'
import { useStateContext } from '../../contexts/ContextProvider'
import { useNavigate } from 'react-router-dom'
import { config, deleteFile, getDocument, updateDocument, uploadData, uploadFile } from '../../lib/appwrite'
import EditVariants from './EditVariants'
import { useParams } from 'react-router-dom'

const EditProduct = () => {
    const navigate = useNavigate()
    const { id } = useParams(); // Extract id from URL
    const { currentColor } = useStateContext()

    let tabs = [
        'Details',
        'Price',
        'Variants',
        'Images gallery'
    ]

    const [currentTab, setCurrentTab] = useState('Details')
    const [active, setActive] = useState(true);

    // Details
    const [name, setName] = useState('');
    const [nameIsFocused, setNameIsFocused] = useState(false);
    const [nameError, setNameError] = useState(false);

    const [sku, setSku] = useState('');
    const [skuIsFocused, setSkuIsFocused] = useState(false);

    const [price, setPrice] = useState('');
    const [priceIsFocused, setPriceIsFocused] = useState(false);
    const [priceError, setPriceError] = useState(false);

    const [description, setDescription] = useState('');
    const [descriptionIsFocused, setDescriptionIsFocused] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [previewSrcArray, setPreviewSrcArray] = useState([]);
    const [photosArray, setPhotosArray] = useState([]);
    const [storedPhotos, setStoredPhotos] = useState([]);

    const hexToRgba = (hex, alpha) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const handleFileRead = (reader) => {
        setPreviewSrcArray(prevArray => [...prevArray, reader.result]);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setPhotosArray(prevArray => [...prevArray, file]);
        const reader = new FileReader();

        reader.onloadend = () => handleFileRead(reader);
        reader.readAsDataURL(file); // Or reader.readAsText(file) depending on your needs
    };

    const deleteImage = async (photoSrc) => {
        if (photoSrc.startsWith('https://database.streamlineautomations.io')) {
            // Handle external links
            try {
                // Perform the API call or any operation to delete the external link
                await deleteFile(photoSrc); // Replace with your actual function to delete the file
                setPreviewSrcArray((prevArray) =>
                    prevArray.filter((src) => src !== photoSrc)
                );
                setStoredPhotos((prevArray) =>
                    prevArray.filter((src) => src !== photoSrc)
                );
            } catch (error) {
                console.error('Error deleting external image:', error);
            }
        } else {
            // Handle local links
            try {
                setPreviewSrcArray((prevArray) =>
                    prevArray.filter((src) => src !== photoSrc)
                );
            } catch (error) {
                console.error('Error removing local image from preview array:', error);
            }
        }
    };

    const submit = async () => {
        try {
            setIsLoading(true)

            // Validation checks
            if (name === '') { setNameError(true); } else { setNameError(false); }

            // Exit if any validation errors exist
            if (
                name === ''
            ) {
                setIsLoading(false);
                return;
            }

            var photoUrls = []

            if (!id) {
                try {
                    // Upload files and collect URLs
                    photoUrls = await Promise.all(photosArray.map(async (image) => {
                        const url = await uploadFile(image, config.photosBucketId);
                        return url;
                    }));

                    // Further processing with `data` (e.g., save to database)
                } catch (error) {
                    console.error('Error uploading photos:', error);
                }
            } else {
                try {
                    // Upload files and collect URLs
                    const newPhotoUrls = await Promise.all(photosArray.map(async (image) => {
                        const url = await uploadFile(image, config.photosBucketId);
                        return url;
                    }));

                    // Merge new photo URLs with existing photos
                    photoUrls = [...storedPhotos, ...newPhotoUrls];

                    // Further processing with `data` (e.g., save to database)
                } catch (error) {
                    console.error('Error uploading photos:', error);
                }
            }

            // Proceed with submitting the data
            const data = {
                name: name,
                description: description,
                price: price,
                photos: photoUrls,
                active: active,
                variants: JSON.stringify(variants),
            };

            let response;

            if (id) {
                response = await updateDocument(id, data, config.merchandiseCollectionId)
            } else {
                response = await uploadData(data, config.merchandiseCollectionId)
            }

            clear();

            setIsLoading(false);

            navigate('/admin/products');
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    };

    const clear = () => {
        setName('');
        setDescription('');
        setPreviewSrcArray([]);
        setVariants([]);
    }

    const [createVariant, setCreateVariant] = useState(false)
    const [variants, setVariants] = useState([]);


    useEffect(() => {
        const prefillData = async () => {
            const data = await getDocument(config.merchandiseCollectionId, id);

            if (data) {
                setActive(data.active || true);
                setName(data.name || '');
                setPrice(data.price || 0);
                setDescription(data.description || '');
                setPreviewSrcArray(data.photos || []);
                setStoredPhotos(data.photos || []);
                try {
                    if (data.variants) {
                        setVariants(JSON.parse(data.variants))
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }

        if (id) {
            prefillData()
        }
    }, [])

    return (
        <div className='mx-[5%]'>
            <div className={`${createVariant ? 'block' : 'hidden'} overflow-scroll`}>
                <EditVariants view={createVariant} setView={setCreateVariant} variants={variants} setVariants={setVariants} mode={'create'} />
            </div>
            <div className='flex items-center space-x-2 cursor-pointer' onClick={() => { navigate('/admin/products') }}>
                <FaArrowLeft />
                <p>Products</p>
            </div>
            <div className='pt-10'><Header title={'Add product'} /></div>
            <div className='flex gap-10'>
                {/* <div className='w-[400px]'>
                    <div className='bg-white border border-gray-100 rounded-2xl shadow-sm'>
                        {tabs.map((tabName, index) => {
                            return <div
                                className={`m-3 p-2 text-gray-600 hover:text-gray-900 rounded-sm hover:bg-gray-100 cursor-pointer ${currentTab === tabName ? 'font-semibold bg-gray-100' : ''}`} key={index}
                                onClick={() => {
                                    setCurrentTab(tabName)
                                }}
                            >{tabName}</div>;
                        })}
                    </div>
                </div> */}
                <div className="w-full flex flex-col gap-y-10">
                    <div className="bg-white border border-gray-100 rounded-2xl shadow-sm p-4">
                        <div className='font-bold text-xs underline underline-offset-4 decoration-gray-300 mb-5'>DETAILS</div>
                        {/* Name */}
                        <div
                            className={`w-full border-6 rounded-2xl ${nameIsFocused ? 'border-4' : 'border-4 border-transparent'} duration-100`}
                            style={{ borderColor: nameIsFocused ? nameError ? hexToRgba('FF0000', 0.4) : hexToRgba(currentColor, 0.4) : 'transparent' }}
                        >
                            <div className={`hover:border-2 hover:border-black duration-300 ${nameError ? 'bg-red-300' : ''} ${nameIsFocused ? 'border-2 border-black' : 'border-2'} rounded-xl`}>
                                <Input1
                                    placeholder={'Name'}
                                    required={true}
                                    isFocused={nameIsFocused}
                                    setIsFocused={setNameIsFocused}
                                    value={name}
                                    onChange={(e) => setName(e)}
                                />
                            </div>
                        </div>
                        <div
                            className={`w-full border-6 rounded-2xl ${priceIsFocused ? 'border-4' : 'border-4 border-transparent'} duration-100`}
                            style={{ borderColor: priceIsFocused ? priceError ? hexToRgba('FF0000', 0.4) : hexToRgba(currentColor, 0.4) : 'transparent' }}
                        >
                            <div className={`hover:border-2 hover:border-black duration-300 ${priceError ? 'bg-red-300' : ''} ${priceIsFocused ? 'border-2 border-black' : 'border-2'} rounded-xl`}>
                                <Input1
                                    placeholder={'Price'}
                                    required={false}
                                    isFocused={priceIsFocused}
                                    setIsFocused={setPriceIsFocused}
                                    value={price}
                                    onChange={(e) => setPrice(e)}
                                />
                            </div>
                        </div>
                        {/* <div className='flex'>
                            <div
                                className={`w-full border-6 rounded-2xl ${skuIsFocused ? 'border-4' : 'border-4 border-transparent'} duration-100`}
                                style={{ borderColor: skuIsFocused ? hexToRgba(currentColor, 0.4) : 'transparent' }}
                            >
                                <div className={`hover:border-2 hover:border-black duration-300 ${skuIsFocused ? 'border-2 border-black' : 'border-2'} rounded-xl`}>
                                    <Input1
                                        placeholder={'SKU'}
                                        required={false}
                                        isFocused={skuIsFocused}
                                        setIsFocused={setSkuIsFocused}
                                        value={sku}
                                        onChange={(e) => setSku(e)}
                                    />
                                </div>
                            </div>
                            
                        </div> */}
                        {/* Description */}
                        {/* <div
                            className={`w-full border-6 rounded-2xl ${descriptionIsFocused ? 'border-4' : 'border-4 border-transparent'} duration-100 mt-2`}
                            style={{ borderColor: descriptionIsFocused ? hexToRgba(currentColor, 0.4) : 'transparent' }}
                        >
                            <div className={`hover:border-2 hover:border-black duration-300 ${descriptionIsFocused ? 'border-2 border-black' : 'border-2'} rounded-xl p-1 pt-2`}>
                                <MessageField
                                    placeholder={'Description'}
                                    value={description}
                                    isFocused={descriptionIsFocused}
                                    setIsFocused={setDescriptionIsFocused}
                                    onChange={(e) => setDescription(e)}
                                />
                            </div>
                        </div> */}

                    </div>
                    <div className="bg-white border border-gray-100 rounded-2xl shadow-sm p-4 mb-5">
                        <div className='flex'>
                            <div className='font-bold text-xs underline underline-offset-4 decoration-gray-300 my-auto'>VARIANTS</div>
                            <div className='hover:bg-gray-800 cursor-pointer flex items-center space-x-2 text-xs bg-black rounded-full text-white px-4 py-2 ml-auto' onClick={() => { setCreateVariant(true) }}>
                                <FaPlus />
                                <p>ADD</p>
                            </div>
                        </div>
                        <div className='grid-cols-2 grid'>
                            <div>
                                <div className="flex flex-wrap gap-4">
                                    {variants.map((variant, index) => (
                                        <div key={index} className='border-2 p-2 rounded-md'>
                                            <div className='flex gap-2'>
                                                <div
                                                    className="flex justify-center items-center border-2 w-[50px] h-[50px] text-center rounded-full font-semibold"
                                                    style={{ backgroundColor: variant.color }}
                                                >
                                                </div>
                                                <div className="flex justify-center items-center border-2 w-[100px] h-[50px] text-center rounded-md font-semibold">
                                                    {variant.size}
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white border border-gray-100 rounded-2xl shadow-sm p-4 mb-5">
                        <div className='font-bold text-xs underline underline-offset-4 decoration-gray-300 mb-5'>IMAGES GALLERY</div>
                        <div className='bg-gray-50 w-full rounded-md shadow-inner shadow-gray-300 p-2'>
                            <div className='border-dashed border-2 border-gray-400 h-full flex flex-col items-center justify-center p-2'>
                                <div className='w-full flex flex-wrap gap-2 justify-center'>
                                    {previewSrcArray.map((photoSrc, index) => (
                                        <div key={index} className='relative flex-shrink-0 w-full md:w-[200px] h-[200px] bg-white rounded-md shadow-md overflow-hidden group'>
                                            <img src={photoSrc} alt={`Preview ${index}`} className='w-full h-full object-cover' />
                                            <div className='absolute inset-0 bg-gray-400 opacity-0 group-hover:opacity-50 transition-opacity duration-300'></div>
                                            <button
                                                onClick={() => deleteImage(photoSrc)}
                                                className='absolute top-2 right-2 p-1 focus:outline-none opacity-0 group-hover:opacity-100 transition-opacity duration-300'
                                            >
                                                <FaTrash className='text-gray-800' />
                                            </button>
                                            <div className='absolute top-2 left-2 w-6 h-6 bg-gray-800 text-white text-xs flex items-center justify-center rounded-full'>
                                                {index + 1}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='my-10'>
                                    <div
                                        className='cursor-pointer flex items-center space-x-2 bg-white hover:bg-gray-100 p-4 shadow-md rounded-md'
                                        onClick={() => document.getElementById('file-input').click()}
                                    >
                                        <FaImage />
                                        <p>Upload image(s)</p>
                                        <input
                                            type="file"
                                            id="file-input"
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFileUpload(e)}
                                        />
                                    </div>
                                    <div className='pt-5 text-sm font text-gray-400'>PNG, JPG, & GIF ACCEPTED</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-[600px]'>
                    <div className='bg-white border border-gray-100 rounded-2xl shadow-sm p-4'>
                        <div
                            className=''
                            onClick={() => { submit() }}
                        >
                            <Button
                                bgColor={currentColor}
                                color={'white'}
                                text={'Save changes'}
                                otherStyles={'w-full rounded-md'}
                                isLoading={isLoading}
                            />
                        </div>
                        <hr className='my-4'></hr>
                        <div className='flex items-center space-x-2'>
                            <ToggleSwitch checked={active} onChange={() => {
                                setActive(!active)
                            }} />
                            <div className='text-xs font-semibold text-gray-600'>{active ? 'ACTIVE' : 'INACTIVE'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProduct