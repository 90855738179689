import React, { useEffect, useState } from 'react'
import hero_bg from '../../assets/hero-bg.png';
import FormField from '../../components/FormField';
import MessageField from '../../components/MessageField';
import { Button } from '../../components';
import { useNavigate } from 'react-router-dom';
import { config, uploadData } from '../../lib/appwrite';

const Review = () => {
    const navigate = useNavigate()
    const [firstName, setFirstName] = useState('')
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastInitial, setLastInitial] = useState('')
    const [lastInitialError, setLastInitialError] = useState(false)
    const [review, setReview] = useState('')
    const [reviewError, setReviewError] = useState(false)

    const [showMessage, setShowMessage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (showMessage) {
          const timer = setTimeout(() => {
            setShowMessage(false);

            navigate('/')
          }, 5000);
    
          return () => clearTimeout(timer);
        }
      }, [showMessage]);

    const submit = async () => {
        if (showMessage === true) { return }

        if (firstName === '') { setFirstNameError(true) } else { setFirstNameError(false) }
        // if (lastInitial === '') { setLastInitialError(true) } else { setLastInitialError(false) }
        if (lastInitial.length !== 1) { setLastInitialError(true) } else { setLastInitialError(false) }
        if (review === '') { setReviewError(true) } else { setReviewError(false) }

        if (
            firstName === '' ||
            lastInitial.length !== 1 ||
            review === ''
        ) { return }

        try {
            setIsLoading(true)

            const data = {
                firstName: firstName,
                lastInitial: lastInitial,
                review: review,
                status: 'Waiting for Review'
            }

            const response = await uploadData(data, config.testimonialCollectionId)

            setShowMessage(true)
            setFirstName('')
            setLastInitial('')
            setReview('')
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <section
                className="relative flex items-center justify-center h-screen bg-cover bg-center pt-20"
                style={{ backgroundImage: `url(${hero_bg})` }}
            >
                <div
                    className="bg-white p-8 rounded-md shadow-lg max-w-lg text-center space-y-2 opacity-95"
                >
                    <h1 className="m-0 text-[32px] font-bold text-[#111] pb-3">Let us know how we did.</h1>
                    <div className='flex space-x-2'>
                        <FormField
                            title={"First Name"}
                            value={firstName}
                            handleChangeText={(e) => setFirstName(e)}
                            error={firstNameError}
                            required={true}
                        />
                        <FormField
                            title={"Last Initial"}
                            value={lastInitial}
                            handleChangeText={(e) => setLastInitial(e)}
                            error={lastInitialError}
                            required={true}
                        />
                    </div>

                    <MessageField
                        title={"Review"}
                        value={review}
                        handleChangeText={(e) => setReview(e)}
                        error={reviewError}
                        required={true}
                    />
                    <p className={`${showMessage ? 'block' : 'hidden'}`}>Thank you for your feedback!</p>
                    <div
                        onClick={() => submit()}
                    >
                        <Button 
                            bgColor={'#000081'}
                            text={'Submit'}
                            borderRadius={'10px'}
                            color={'white'}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Review