import React, { useState, useRef } from 'react';
import { IoCartOutline } from 'react-icons/io5';
import { RiDeleteBinLine } from 'react-icons/ri'; // Import delete bin icon
import Button from './Button';
import { useNavigate } from 'react-router-dom';

const CartIcon = ({ items, setItems }) => {
    const navigate = useNavigate()
    const [showDropdown, setShowDropdown] = useState(false);
    const timeoutRef = useRef(null); // useRef to store the timeout reference

    const handleMouseEnter = () => {
        clearTimeout(timeoutRef.current); // Clear any existing timeout
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        // Set a timeout to hide the dropdown after 100ms (1 second)
        timeoutRef.current = setTimeout(() => {
            setShowDropdown(false);
        }, 100);
    };

    const handleDropdownMouseEnter = () => {
        clearTimeout(timeoutRef.current); // Clear any existing timeout
        setShowDropdown(true);
    };

    const handleDropdownMouseLeave = () => {
        // Set a timeout to hide the dropdown after 100ms (1 second)
        timeoutRef.current = setTimeout(() => {
            setShowDropdown(false);
        }, 100);
    };

    // Function to combine items with the same SKU and calculate quantities
    const combineItemsBySku = () => {
        const combinedItems = [];
        items.forEach((item) => {
            const existingItem = combinedItems.find((ci) => ci.selectedProduct === item.selectedProduct);
            if (existingItem) {
                existingItem.qty += 1; // Increase quantity
            } else {
                combinedItems.push({ ...item, qty: 1 }); // Add new item with quantity 1
            }
        });
        return combinedItems;
    };

    // Remove an item from items array
    const handleRemoveItem = (selectedProduct) => {
        // Filter out the item with the specified ID
        const updatedItems = items.filter((item) => item.selectedProduct !== selectedProduct);
        // Update the state with the new array of items
        setItems(updatedItems);

        // Save the updated items array to localStorage
        localStorage.setItem('cart', JSON.stringify(updatedItems));
    };


    const combinedItems = combineItemsBySku();

    return (
        <div className="relative" onClick={() => { navigate('/cart') }}>
            <div className="relative">
                <IoCartOutline
                    className="text-2xl cursor-pointer"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
                {items && items.length > 0 && (
                    <div
                        className="absolute top-0 right-0 bg-[#000081] text-white w-3 h-3 flex items-center justify-center rounded-full text-[8px]"
                    >
                        {items.length}
                    </div>
                )}
            </div>

            {/* {showDropdown && (
                <div
                    className="absolute mt-1 py-2 px-3 bg-white shadow-lg rounded-lg w-[200px] right-0 z-10"
                    onMouseEnter={handleDropdownMouseEnter}
                    onMouseLeave={handleDropdownMouseLeave}
                >
                    {combinedItems.length > 0 ? (
                        <div>
                            {combinedItems.map((item, index) => (
                                <div key={index} className="flex items-center mb-1">
                                    <img src={item.photo} alt={item.name} className="w-6 h-6 mr-2" />
                                    <span>{item.name} - x{item.qty}</span>
                                    <button
                                        className="ml-auto"
                                        onClick={() => handleRemoveItem(item.selectedProduct)}
                                    >
                                        <RiDeleteBinLine className="text-red-400 cursor-pointer" />
                                    </button>
                                </div>
                            ))}
                            <div
                                className='pt-4 text-center'
                                onClick={() => navigate('/cart')}
                            >
                                <Button
                                    text={"Go to Cart"}
                                    size={"sm"}
                                    bgColor={"#000081"}
                                    borderRadius={"5px"}
                                    color={"white"}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="text-gray-500">Empty Cart</div>
                    )}

                </div>
            )} */}
        </div>
    );
};

export default CartIcon;
