import React from 'react'
import { BiSolidBinoculars } from 'react-icons/bi'
import { BsHouseDoor } from 'react-icons/bs'
import { FaCat, FaDog, FaHorse, FaMapMarkerAlt, FaPaw, FaRegHeart, FaTaxi } from 'react-icons/fa'
import { GiChicken, GiGecko, GiRabbit, GiSeaTurtle, GiSheep } from 'react-icons/gi'
import { IoFish } from 'react-icons/io5'
import { LuBird } from 'react-icons/lu'
import { VscSnake } from "react-icons/vsc";

const Pricing = () => {
    return (
        <div className="relative">
            <div className="absolute inset-0 bg-[#1b1b1b] transform -skew-y-3"></div>
            <section className="py-32 text-white mx-[15%]">
                <div className="mx-auto" data-aos="fade-up">
                    <div className="text-center pb-[10px] relative">
                        <div className="text-[32px] font-bold uppercase mb-5 pb-5 relative">
                            <h2>Pricing</h2>
                            <span className="block w-14 h-1 bg-[#000081] absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                        </div>
                    </div>
                    <p className='mb-0 text-center'>$5 per additional animal, cage, or tank</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="mt-8">
                            <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="100">
                                <i className='float-left text-[#000081] text-[40px]'><FaDog /></i>
                                <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Dogs</h4>
                                <p className="ml-[70px] text-[14px] mb-[10px]"></p>
                                <ul className="ml-[70px] text-lg text-gray-200 list-disc pl-6">
                                    <li>Walks: $20 per 30 minute walk.</li>
                                    <li>Overnights: $60 per night.</li>
                                    <li>Daily Visits: $20 per 30 minute drop in.</li>
                                    <li>Pet Taxi: $7.50 per mile.</li>
                                    <li>Special care pricing available upon request.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="mt-8">
                            <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="200">
                                <i className='float-left text-[#000081] text-[40px]'><FaCat /></i>
                                <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Cats</h4>
                                <p className="ml-[70px] text-[14px] mb-[10px]"></p>
                                <ul className="ml-[70px] text-lg text-gray-200 list-disc pl-6">
                                    <li>Overnights: $60 per night.</li>
                                    <li>Daily Visits: $20 per 30 minute drop in.</li>
                                    <li>Pet Taxi: $7.50 per mile.</li>
                                    <li>Special care pricing available upon request.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="300">
                                <i className='float-left text-[#000081] text-[40px]'><LuBird /></i>
                                <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Birds</h4>
                                <p className="ml-[70px] text-[14px] mb-[10px]"></p>
                                <ul className="ml-[70px] text-lg text-gray-200 list-disc pl-6">
                                    <li>Overnights: $60 per night.</li>
                                    <li>Daily Visits: $20 per 30 minute drop in.</li>
                                    <li>Pet Taxi: $7.50 per mile.</li>
                                    <li>Special care pricing available upon request.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="400">
                                <i className='float-left text-[#000081] text-[40px]'><GiRabbit /></i>
                                <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Small Animals</h4>
                                <p className="ml-[70px] text-[14px] mb-[10px]"></p>
                                <ul className="ml-[70px] text-lg text-gray-200 list-disc pl-6">
                                    <li>Overnights: $60 per night.</li>
                                    <li>Daily Visits: $20 per 30 minute drop in.</li>
                                    <li>Pet Taxi: $7.50 per mile.</li>
                                    <li>Special care pricing available upon request.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="400">
                                <i className='float-left text-[#000081] text-[40px]'><GiGecko /></i>
                                <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Lizards</h4>
                                <p className="ml-[70px] text-[14px] mb-[10px]"></p>
                                <ul className="ml-[70px] text-lg text-gray-200 list-disc pl-6">
                                    <li>Overnights: $60 per night.</li>
                                    <li>Daily Visits: $20 per 30 minute drop in.</li>
                                    <li>Pet Taxi: $7.50 per mile.</li>
                                    <li>Special care pricing available upon request.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="400">
                                <i className='float-left text-[#000081] text-[40px]'><VscSnake /></i>
                                <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Snakes</h4>
                                <p className="ml-[70px] text-[14px] mb-[10px]"></p>
                                <ul className="ml-[70px] text-lg text-gray-200 list-disc pl-6">
                                    <li>Overnights: $60 per night.</li>
                                    <li>Daily Visits: $20 per 30 minute drop in.</li>
                                    <li>Pet Taxi: $7.50 per mile.</li>
                                    <li>Special care pricing available upon request.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="400">
                                <i className='float-left text-[#000081] text-[40px]'><GiSeaTurtle /></i>
                                <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Turtles</h4>
                                <p className="ml-[70px] text-[14px] mb-[10px]"></p>
                                <ul className="ml-[70px] text-lg text-gray-200 list-disc pl-6">
                                    <li>Overnights: $60 per night.</li>
                                    <li>Daily Visits: $20 per 30 minute drop in.</li>
                                    <li>Pet Taxi: $7.50 per mile.</li>
                                    <li>Special care pricing available upon request.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="400">
                                <i className='float-left text-[#000081] text-[40px]'><IoFish /></i>
                                <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Fish</h4>
                                <p className="ml-[70px] text-[14px] mb-[10px]"></p>
                                <ul className="ml-[70px] text-lg text-gray-200 list-disc pl-6">
                                    <li>Overnights: $60 per night.</li>
                                    <li>Daily Visits: $20 per 30 minute drop in.</li>
                                    <li>Pet Taxi: $7.50 per mile.</li>
                                    <li>Special care pricing available upon request.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="400">
                                <i className='float-left text-[#000081] text-[40px]'><GiChicken /></i>
                                <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Poultry</h4>
                                <p className="ml-[70px] text-[14px] mb-[10px]"></p>
                                <ul className="ml-[70px] text-lg text-gray-200 list-disc pl-6">
                                    <li>Overnights: $70 per night.</li>
                                    <li>Daily Visits: $30 per 30 minute drop in.</li>
                                    <li>Special care pricing available upon request.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="400">
                                <i className='float-left text-[#000081] text-[40px]'><GiSheep /></i>
                                <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Sheep/Goats</h4>
                                <p className="ml-[70px] text-[14px] mb-[10px]"></p>
                                <ul className="ml-[70px] text-lg text-gray-200 list-disc pl-6">
                                    <li>Overnights: $70 per night.</li>
                                    <li>Daily Visits: $30 per 30 minute drop in.</li>
                                    <li>Special care pricing available upon request.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="400">
                                <i className='float-left text-[#000081] text-[40px]'><FaHorse /></i>
                                <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Horses</h4>
                                <p className="ml-[70px] text-[14px] mb-[10px]"></p>
                                <ul className="ml-[70px] text-lg text-gray-200 list-disc pl-6">
                                    <li>Overnights: $80 per night.</li>
                                    <li>Daily Visits: $40 per 30 minute drop in.</li>
                                    <li>Exercise pricing available upon request.</li>
                                    <li>Special care pricing available upon request.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="400">
                                <i className='float-left text-[#000081] text-[40px]'><FaPaw /></i>
                                <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Other</h4>
                                <p className="ml-[70px] text-[14px] mb-[10px]"></p>
                                <ul className="ml-[70px] text-lg text-gray-200 list-disc pl-6">
                                    <li>Overnights: $60 per night.</li>
                                    <li>Daily Visits: $20 per 30 minute drop in.</li>
                                    <li>Pet Taxi: $7.50 per mile.</li>
                                    <li>Special care pricing available upon request.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Pricing