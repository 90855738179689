import React, { useState, useRef, useEffect } from 'react';
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { Header } from '../../../components'
import useAppwrite from '../../../lib/useAppwrite';
import { config, deleteDocument, getAllDocuments, getCurrentUser } from '../../../lib/appwrite';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../../../contexts/ContextProvider';
import { FaEye, FaRegEdit } from 'react-icons/fa';
import { FaCirclePlus, FaRegEye, FaTrashCan } from "react-icons/fa6";
// import EditUser from './EditUser';
import EditableTable from '../../../components/EditableTable';
import { TbEye, TbEyeCog } from "react-icons/tb";
import EditMessage from './EditMessage';
import ConfirmDelete from '../../../components/ConfirmDelete';


const Messages = () => {
  const { data: user } = useAppwrite(
    () => getCurrentUser()
  );

  const { currentColor } = useStateContext();
  const [viewRecord, setViewRecord] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  // Table variables
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [reset, setReset] = useState(false)
  const [messages, setMessages] = useState([])

  useEffect(() => {
    if (viewRecord === false) {
      refetch()
    }
  }, [viewRecord]);

  useEffect(() => {
    if (!selectedRowData) {
      setReset(true)
    }
  }, [selectedRowData])

  const handleRowSelect = (data) => {
    setSelectedRowData(data);
  };

  const handleDelete = async () => {
    setConfirmDelete(false)
    await deleteDocument(selectedRowData.$id, config.messagesCollectionId)
    refetch()
    setSelectedRowData(null)
  }

  // Data
  const { data: data, isLoading, refetch } = useAppwrite(
    () => getAllDocuments(config.messagesCollectionId)
  );

  useEffect(() => {
    if (data) {
      setMessages(data);
    }
  }, [data]);
  // ======================================== Edit QualityRecord Vars ======================================== //


  return (
    <div className="m-10 md:m-10 mt-24 p-10 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <div className={`${viewRecord ? 'block' : 'hidden'} overflow-scroll`}>
        <EditMessage view={viewRecord} setView={setViewRecord} selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} mode={'view'} />
      </div>
      <Header category="" title="Messages" isLoading={isLoading} />

      <div className='flex flex-1 justify-end items-center my-2'>
        <div className={`${selectedRowData ? 'block' : 'hidden'}`}>
          <TooltipComponent content="Review Message" position='TopCenter'>
            <button
              type='button'
              onClick={() => setViewRecord(true)}
              style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaRegEye className='text-xl' style={{ color: currentColor }} />
            </button>
          </TooltipComponent>
        </div>
        <div className={`${selectedRowData ? 'block' : 'hidden'}`}>
          <TooltipComponent content="Delete Message" position='TopCenter'>
            <button
              type='button'
              onClick={() => setConfirmDelete(true)}
              style={{ borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaTrashCan className='text-xl text-red-500' />
            </button>
          </TooltipComponent>
        </div>
      </div>
      <EditableTable
        title=""
        data={messages}
        setData={setMessages}
        rowActions={[]}
        keyMapping={{
          'Name': 'name',
          'Email': 'email',
          'Subject': 'subject',
          // 'Message': 'message'
        }}
        onRowClick={handleRowSelect} // Assuming handleRowSelect is a defined function
        reset={reset}
        setReset={setReset}
        selectable={true}
      />
      <ConfirmDelete
        title="Confirm Deletion?"
        text="Are you sure you want to delete the following? This action cannot be undone."
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        visible={confirmDelete}
        setVisible={setConfirmDelete}
        handleConfirm={() => handleDelete()}
      />
    </div>
  );
};

export default Messages;