import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';
import { Pagination, Autoplay } from 'swiper/modules';
import noPhoto from '../assets/noPhoto.png';

const TestimonialSwiper = ({ testimonials }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Swiper
      pagination={{ clickable: true }}
      modules={[Pagination, Autoplay]}
      className='mySwiper'
      autoplay={{ delay: 5000, disableOnInteraction: false }}
      onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
    >
      {testimonials.map((testimonial, index) => (
        <SwiperSlide key={index}>
          <div className={`${currentIndex === index ? '' : 'hidden'}`}>
            <div className='text-center text-[#fff] italic flex flex-col justify-center items-center mb-10'>
              <img
                src={noPhoto}
                className='w-[100px] rounded-[50%] border-[6px] border-[rgba(255,255,255,0.15)] mb-4'
                alt={testimonial.name}
              />
              <div>
                <h3 className='text-[20px] font-bold mt-0 mb-2 text-[#fff]'>{testimonial.firstName} {testimonial.lastInitial}.</h3>
                <p className='mx-auto mb-6 text-[#eee] sm:w-full'>
                  <RiDoubleQuotesL className='text-[rgba(255,255,255,0.4)] inline-block left-[-5px] top-[-10px] relative' />
                  {testimonial.review}
                  <RiDoubleQuotesR className='text-[rgba(255,255,255,0.4)] inline-block right-[-5px] top-[10px] relative' />
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TestimonialSwiper;
