import React from 'react'
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri'
import noPhoto from '../assets/noPhoto.png'

const Testimonial = ({ id, photoUrl, name, comment }) => {
    return (
        <div className='text-center text-[#fff] italic flex flex-col justify-center items-center mb-10'>
            <img src={photoUrl && photoUrl !== '' ? photoUrl : noPhoto} className='w-[100px] rounded-[50%] border-[6px] border-[rgba(255,255,255,0.15)] mb-4' alt="" />
            <div>
                <h3 className='text-[20px] font-bold mt-0 mb-2 text-[#fff]'>{name}</h3>
                {/* <!-- <h4>Ceo &amp; Founder</h4> --> */}
                <p className='min-w-[992px] mx-auto mb-6 text-[#eee] w-[50%]'>
                    <RiDoubleQuotesL className='text-[rgba(255,255,255,0.4)] inline-block left-[-5px] top-[-10px] relative' />
                    {comment}
                    <RiDoubleQuotesR className='text-[rgba(255,255,255,0.4)] inline-block right-[-5px] top-[10px] relative' />
                </p>
            </div>
        </div>
    )
}

export default Testimonial
