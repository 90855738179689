import React, { useState, useRef, useEffect } from 'react';
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { Header } from '../../../../components';
import useAppwrite from '../../../../lib/useAppwrite';
import { config, deleteDocument, getAllDocuments, getCurrentUser } from '../../../../lib/appwrite';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { FaEye, FaRegEdit } from 'react-icons/fa';
import { FaCirclePlus, FaTrashCan } from "react-icons/fa6";
import EditUser from './EditUser';
import EditableTable from '../../../../components/EditableTable';
import { TbEye, TbEyeCog } from "react-icons/tb";
import EditPageControl from './EditPageControl';
import ConfirmDelete from '../../../../components/ConfirmDelete';


const Users = () => {
  const { data: user } = useAppwrite(
    () => getCurrentUser()
  );

  const { currentColor } = useStateContext();
  const [editUserData, setEditUserData] = useState(false)
  const [modifyPageControl, setModifyPageControl] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  // Table variables
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [reset, setReset] = useState(false)
  const [users, setUsers] = useState([])

  useEffect(() => {
    if (editUserData === false) {
      refetch()
    }
  }, [editUserData, modifyPageControl]);

  useEffect(() => {
    if (!selectedRowData) {
      setReset(true)
    }
  }, [selectedRowData])

  const handleRowSelect = (data) => {
    setSelectedRowData(data);
  };

  const handleDelete = async () => {
    setConfirmDelete(false)
    await deleteDocument(selectedRowData.$id, config.userCollectionId)
    refetch()
    setSelectedRowData(null)
  }

  // Data
  const { data: data, refetch } = useAppwrite(
    () => getAllDocuments(config.userCollectionId)
  );
  
  useEffect(() => {
    if (data) {
      setUsers(data);
    }
  }, [data]);
  // ======================================== Edit QualityRecord Vars ======================================== //


  return (
    <div>
      <div className={`${editUserData ? 'block' : 'hidden'} overflow-scroll`}>
        <EditUser editUserData={editUserData} setEditUserData={setEditUserData} selectedRowData={selectedRowData} />
      </div>
      <div className={`${modifyPageControl ? 'block' : 'hidden'} overflow-scroll`}>
        <EditPageControl view={modifyPageControl} setView={setModifyPageControl} selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} mode='edit' />
      </div>
      <Header category="" title="Users" />

      <div className='flex flex-1 justify-end items-center my-2'>
        <div className={`${selectedRowData ? 'block' : 'hidden'}`}>
          <TooltipComponent content="Edit User" position='TopCenter'>
            <button
              type='button'
              onClick={() => setEditUserData(true)}
              style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaRegEdit className='text-xl' style={{ color: currentColor }} />
            </button>
          </TooltipComponent>
        </div>
        <div className={`${selectedRowData && user.permissions === 
          'admin' ? 'block' : 'hidden'}`}>
          <TooltipComponent content="Modify Permissions" position='TopCenter'>
            <button
              type='button'
              onClick={() => setModifyPageControl(true)}
              style={{ borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <TbEyeCog className='text-xl' style={{ color: currentColor }} />
            </button>
          </TooltipComponent>
        </div>
        <div className={`${selectedRowData && user.permissions === 
          'admin' ? 'block' : 'hidden'}`}>
          <TooltipComponent content="Delete User" position='TopCenter'>
            <button
              type='button'
              onClick={() => setConfirmDelete(true)}
              style={{ borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaTrashCan className='text-xl text-red-500' />
            </button>
          </TooltipComponent>
        </div>
      </div>
      <EditableTable
        title=""
        data={users}
        setData={setUsers}
        rowActions={[]}
        keyMapping={{
          'Username': 'username',
          'Email': 'email',
          'Permissions': 'permissions',
        }}
        onRowClick={handleRowSelect} // Assuming handleRowSelect is a defined function
        reset={reset}
        setReset={setReset}
        selectable={true}
      />
      <ConfirmDelete
        title="Confirm Deletion?"
        text="Are you sure you want to delete the following? This action cannot be undone." 
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        visible={confirmDelete}
        setVisible={setConfirmDelete}
        handleConfirm={() => handleDelete()}
      />
    </div>
  );
};

export default Users;