import React, { useEffect, useState, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useStateContext } from '../../../contexts/ContextProvider';
import EditableTable from '../../../components/EditableTable';
import { Button } from '../../../components';
import FormField from '../../../components/FormField';
import { updateDocument, config, uploadData } from '../../../lib/appwrite';
import DateInput from '../../../components/DateField';
import DropdownField from '../../../components/DropdownField';
import MessageField from '../../../components/MessageField';

const EditBooking = ({ view, setView, selectedRowData, setSelectedRowData, mode }) => {
    const { currentColor } = useStateContext();

    const [firstName, setFirstName] = useState('')
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastInitial, setLastInitial] = useState('')
    const [lastInitialError, setLastInitialError] = useState(false)
    const [review, setReview] = useState('')
    const [reviewError, setReviewError] = useState(false)
    const [status, setStatus] = useState('')
    const [statusError, setStatusError] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (view && selectedRowData) {
            setFirstName(selectedRowData.firstName)
            setLastInitial(selectedRowData.lastInitial)
            setReview(selectedRowData.review)
            setStatus(selectedRowData.status)
        }
    }, [view]);

    const close_menu = () => {
        setFirstName('')
        setLastInitial('')
        setReview('')
        setStatus('')
        setSelectedRowData(null);
        setView(false);
        setIsLoading(false);
    };

    const submit = async () => {
        if (firstName === '') { setFirstNameError(true) } else { setFirstNameError(false) }
        // if (lastInitial === '') { setLastInitialError(true) } else { setLastInitialError(false) }
        if (lastInitial.length !== 1) { setLastInitialError(true) } else { setLastInitialError(false) }
        if (review === '') { setReviewError(true) } else { setReviewError(false) }

        if (
            firstName === '' ||
            lastInitial.length !== 1 ||
            review === ''
        ) { return }

        try {
            setIsLoading(true)

            const data = {
                firstName: firstName,
                lastInitial: lastInitial,
                review: review,
                status: status
            }

            const response = await updateDocument(selectedRowData.$id, data, config.testimonialCollectionId)

            close_menu()
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0'>
            <div className='float-right h-screen overflow-scroll dark:text-gray-200 bg-white dark:bg-secondary-dark-bg w-[600px]'>
                <div className='flex justify-between items-center p-4 ml-4'>
                    {mode === 'edit' && (
                        <p className='font-semibold text-xl'>Edit Quality Record</p>
                    )}
                    {mode === 'create' && (
                        <p className='font-semibold text-xl'>Create Quality Record</p>
                    )}
                    {mode === 'view' && (
                        <p className='font-semibold text-xl'>Quality Record</p>
                    )}
                    <button
                        type='button'
                        onClick={() => close_menu()}
                        style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                    >
                        <MdOutlineCancel />
                    </button>
                </div>

                <div className='flex-col border-t-1 border-color p-4 ml-4 space-y-6'>
                    <FormField
                        title="First Name"
                        placeholder="First Name"
                        value={firstName}
                        handleChangeText={(e) => setFirstName(e)}
                        error={firstNameError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <FormField
                        title="Last Initial"
                        placeholder="Last Initial"
                        value={lastInitial}
                        handleChangeText={(e) => setLastInitial(e)}
                        error={lastInitialError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <DropdownField 
                        selected={status}
                        setSelected={setStatus}
                        title={"Status"}
                        options={[
                            'Approved',
                            'Waiting for Review',
                            'Denied'
                        ]}
                        handleChangeText={(e) => setStatus(e)}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                        error={statusError}
                        searchable={true}
                    />

                    <MessageField 
                        title={"Review"}
                        value={review}
                        placeholder={"Review"}
                        handleChangeText={(e) => setReview(e)}
                        required={mode !== 'view'}
                        error={reviewError}
                    />

                    <div
                        onClick={() => {
                            if (!isLoading) {
                                setTimeout(submit, 100)
                            }
                        }}
                    >
                        <Button
                            bgColor={currentColor}
                            text="Submit"
                            size="md"
                            borderRadius={"12px"}
                            otherStyles="w-full items-center text-center font-semibold text-white"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EditBooking;