
// Auth
export { default as SignIn } from './auth/SignIn';
export { default as SignUp } from './auth/SignUp'; 

// Backend
export { default as Dashboard } from './backend/Dashboard';
export { default as AccessControl } from './backend/AccessControl/AccessControl'
export { default as Testimonials } from './backend/testimonials/Testimonials'
export { default as Messages } from './backend/messages/Messages'
export { default as Leadership } from './backend/leadership/Leadership'
export { default as Team } from './backend/team/Team'
export { default as Customers } from './backend/customers/Customers'
export { default as Calendar } from './backend/calendar/Calendar'
export { default as Orders } from './Orders';

// Front End
export { default as Home } from './home/Home'
export { default as Review } from './home/Review'
export { default as Booking } from './home/Booking'
export { default as Pricing } from './home/Pricing'
export { default as ProductView } from './home/ProductView'
export { default as Cart } from './home/Cart';
export { default as Checkout } from './home/Checkout';
export { default as Thankyou } from './home/Thankyou';
