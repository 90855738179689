import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentUser } from './lib/appwrite';
import * as Pages from './pages/index';
import { Routes, Route } from 'react-router-dom';
import { useStateContext } from './contexts/ContextProvider';
import Loading from './pages/Loading';
import AllProducts from './pages/Products/AllProducts';
import EditProduct from './pages/Products/EditProduct';
import useAppwrite from './lib/useAppwrite';

const ProtectedRoutes = ({ isAllowed }) => {
    return (
        <Routes>
            <Route path="/" element={<Pages.Dashboard />} />
            <Route path='/access-control' element={<Pages.AccessControl />} />
            <Route path="/admin/dashboard" element={<Pages.Dashboard />} />
            {/* <Route path="/admin/testimonials" element={<Pages.Testimonials />} /> */}
            <Route path="/admin/messages" element={<Pages.Messages />} />
            <Route path="/admin/leadership" element={<Pages.Leadership />} />
            <Route path="/admin/team" element={<Pages.Team />} />
            {/* <Route path="/admin/customers" element={<Pages.Customers />} /> */}
            {/* <Route path="/admin/calendar" element={<Pages.Calendar />} /> */}
            <Route path="/admin/products" element={<AllProducts />} />
            <Route path="/admin/products/add" element={<EditProduct />} />
            <Route path="/admin/products/edit/:id" element={<EditProduct />} />
            <Route path="/admin/orders" element={<Pages.Orders />} />

        </Routes>
    );
};

const ProtectedContent = () => {
    const location = useLocation();
    const [isAllowed, setIsAllowed] = useState(false);
    const { fetchAllowedPages } = useStateContext()
    const [allowedPages, setAllowedPages] = useState([])

    const { data: user, isLoading:loading } = useAppwrite(
        () => getCurrentUser()
    );

    if (loading) {
        return <Loading />; // Or a spinner component
    }

    return user.permissions === 'admin' ? <ProtectedRoutes /> : <div>Access Denied</div>;
};

export default ProtectedContent;

