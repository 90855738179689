import React, { useState, useRef, useEffect } from 'react';
import useAppwrite from '../../lib/useAppwrite';
import { config, deleteDocument, deleteFile, getAllDocuments, getCurrentUser, getDocument } from '../../lib/appwrite';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../../contexts/ContextProvider';
import { FaEye, FaPlus, FaRegEdit } from 'react-icons/fa';
import { FaCirclePlus, FaRegEye, FaTrashCan } from "react-icons/fa6";
import { TbEye, TbEyeCog } from "react-icons/tb";
import { ConfirmDelete, EditableTable, Header, MerchandiseCard, ProductSetup } from '../../components'
import EditProduct from './EditProduct';
import { useNavigate } from 'react-router-dom';
import { CgTrash } from 'react-icons/cg';

const AllProducts = () => {
  const navigate = useNavigate()
  const { currentColor } = useStateContext()

  const { data: user } = useAppwrite(
    () => getCurrentUser()
  );

  const [confirmDelete, setConfirmDelete] = useState(false)

  const { data: products, isLoading, refetch } = useAppwrite(
    () => getAllDocuments(config.merchandiseCollectionId)
  );

  // Table variables
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [reset, setReset] = useState(false)
  const [messages, setMessages] = useState([])

  useEffect(() => {
    if (!selectedRowData) {
      setReset(true)
    }
  }, [selectedRowData])

  const deleteProduct = async (id) => {
    try {
      // Get the document data
      const documentData = await getDocument(config.merchandiseCollectionId, id);

      // Check if the document has photos
      if (documentData.photos && documentData.photos.length > 0) {
        // Delete each photo
        await Promise.all(
          documentData.photos.map(async (url) => {
            await deleteFile(url); // Ensure deleteFile is an async function
          })
        );
      }

      // Delete the document
      await deleteDocument(id, config.merchandiseCollectionId);

      // Refetch data or update state
      refetch();
    } catch (error) {
      console.error('Error deleting product:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  // ======================================== Edit QualityRecord Vars ======================================== //


  return (
    <div className="m-6 p-10 md:m-10 mt-24 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <div className='flex'>
        <Header category="" title="Products" isLoading={isLoading} />
        <div 
        className='hover:bg-gray-800 cursor-pointer flex items-center space-x-2 text-xs bg-black rounded-full text-white px-4 py-2 h-[30px] ml-auto' 
        onClick={() => { navigate('/admin/products/add') }}
        style={{ backgroundColor: currentColor }}
        >
          <FaPlus />
          <p>ADD</p>
        </div>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl overflow-hidden sm:px-6 lg:px-8">
          <h2 className="sr-only">Products</h2>

          <div className="-mx-px grid grid-cols-2 sm:mx-0 md:grid-cols-3 lg:grid-cols-4 gap-5">
            {products.map((product, index) => (
              <div
                key={index}
                className="relative bg-gray-100 rounded-xl shadow-md group hover:bg-gray-200"
              >
                <MerchandiseCard
                  item={product}
                  onClick={() => {
                    navigate(`/admin/products/edit/${product.$id}`);
                  }}
                />
                {/* Trashcan button */}
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents triggering onClick of MerchandiseCard
                    deleteProduct(product.$id);
                  }}
                  className="absolute top-2 right-2 p-2 text-red-600 hover:text-red-800 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                >
                  <CgTrash className='text-xl' />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

  );
};

export default AllProducts;