import React, { useState, useRef, useEffect } from 'react';
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Toolbar, Edit, Filter, Sort, create } from '@syncfusion/ej2-react-grids';
import { Header } from '../../../../components';
import useAppwrite from '../../../../lib/useAppwrite';
import { config, deleteDocument, getAllDocuments } from '../../../../lib/appwrite';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { FaRegEdit } from 'react-icons/fa';
import { FaCirclePlus, FaTrashCan } from "react-icons/fa6";
import EditableTable from '../../../../components/EditableTable';
import EditPageControl from '../Users/EditPageControl';
import EditAccessGroup from './EditAccessGroup';
import ConfirmDelete from '../../../../components/ConfirmDelete';


const PageControl = () => {
  const { currentColor } = useStateContext();
  const [createRecord, setCreateRecord] = useState(false)
  const [editRecord, setEditRecord] = useState(false)
  const [reset, setReset] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [records, setRecords] = useState([])

  const { data: data, isLoading, refetch } = useAppwrite(
    () => getAllDocuments(config.permissionsCollectionId, null, [{customAccess: false}])
  );

  useEffect(() => {
    if (data) {
      setRecords(data);
    }
  }, [data]);

  useEffect(() => {
    if (editRecord === false || createRecord === false) {
      refetch()
    }
  }, [editRecord, createRecord]);

  useEffect(() => {
    if (!selectedRowData) {
      setReset(true)
    }
  }, [selectedRowData])

  // Data
  

  const handleRowSelect = (data) => {
    setSelectedRowData(data);
  };

  const handleDelete = async () => {
    setConfirmDelete(false)
    await deleteDocument(selectedRowData.$id, config.permissionsCollectionId)
    refetch()
    setSelectedRowData(null)
  }

  // ======================================== Edit Product Vars ======================================== //


  return (
    <div>
      <div className={`${createRecord ? 'block' : 'hidden'} overflow-scroll`}>
        <EditAccessGroup view={createRecord} setView={setCreateRecord} selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} mode={'create'}/>
      </div>
      <div className={`${editRecord ? 'block' : 'hidden'} overflow-scroll`}>
      <EditAccessGroup view={editRecord} setView={setEditRecord} selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} mode={'edit'}/>
      </div>
      <Header category="" title="Permission Groups" isLoading={isLoading}/>

      <div className='flex flex-1 justify-end items-center my-2'>
        <TooltipComponent content="Create Group" position='TopCenter'>
          <button
            type='button'
            onClick={() => setCreateRecord(true)}
            style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
            className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
          >
            <FaCirclePlus className='text-xl' style={{ color: currentColor }} />
          </button>
        </TooltipComponent>
        <div className={`${selectedRowData ? 'block' : 'hidden'}`}>
          <TooltipComponent content="Edit Group" position='TopCenter'>
            <button
              type='button'
              onClick={() => setEditRecord(true)}
              style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaRegEdit className='text-xl' style={{ color: currentColor }} />
            </button>
          </TooltipComponent>
        </div>
        <div className={`${selectedRowData ? 'block' : 'hidden'}`}>
          <TooltipComponent content="Delete Group" position='TopCenter'>
            <button
              type='button'
              onClick={() => setConfirmDelete(true)}
              style={{ borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaTrashCan className='text-xl text-red-500' />
            </button>
          </TooltipComponent>
        </div>
      </div>
      <EditableTable
        title=""
        data={records}
        setData={setRecords}
        rowActions={[]}
        keyMapping={{
          'Group Name': 'permissionName'
        }}
        onRowClick={handleRowSelect} // Assuming handleRowSelect is a defined function
        reset={reset}
        setReset={setReset}
        selectable={true}
      />
      <ConfirmDelete
        title="Confirm Deletion?"
        text="Are you sure you want to delete the following? This action cannot be undone." 
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        visible={confirmDelete}
        setVisible={setConfirmDelete}
        handleConfirm={() => handleDelete()}
      />
    </div>
  );
};

export default PageControl;