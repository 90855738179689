import React, { useState } from 'react';
import '../css/Loading.css'
import { useStateContext } from '../contexts/ContextProvider';

const Loading = () => {
    const { currentColor } = useStateContext()

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className={`loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32 border-t-gray-400`}></div>
    </div>
  );
};

export default Loading;