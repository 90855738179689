import { useState, useEffect } from 'react';
import { StarIcon } from '@heroicons/react/20/solid';
import { Radio, RadioGroup } from '@headlessui/react';
import { CurrencyDollarIcon, GlobeAmericasIcon } from '@heroicons/react/24/outline';
import { config, getDocument } from '../../lib/appwrite';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';

const policies = [
  // { name: 'International delivery', icon: GlobeAmericasIcon, description: 'Get your order in 2 years' },
  // { name: 'Loyalty rewards', icon: CurrencyDollarIcon, description: "Don't look at other tees" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ProductView = () => {
  const { setCartItems } = useStateContext()
  const { id } = useParams(); // Extract id from URL
  const [product, setProduct] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState('');
  const [selectedColor, setSelectedColor] = useState('')
  const [selectedSize, setSelectedSize] = useState(null);

  const [images, setImages] = useState([]);
  const [colors, setColors] = useState([]);
  const [allowedColors, setAllowedColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [allowedSizes, setAllowedSizes] = useState([]);

  useEffect(() => {
    const loadProductData = async () => {
      try {
        // const stripeData = await getProduct(id)
        // setSelectedSku(stripeData.default_price)
        // setImages(stripeData.images)

        // const priceData = await getPrice(stripeData.default_price);
        // const price = parseInt(priceData.unit_amount) / 100
        // setSelectedPrice(price);


        const data = await getDocument(config.merchandiseCollectionId, id);
        if (data && data.variants) {
          setImages(data.photos)
          setSelectedPrice(data.price)

          try {
            const variantData = JSON.parse(data.variants);

            // Extract unique colors and sizes
            const colorsSet = new Set();
            const sizesSet = new Set();

            variantData.forEach(variant => {
              if (variant.color) colorsSet.add(variant.color);
              if (variant.size) sizesSet.add(variant.size);
            });

            const colors = Array.from(colorsSet);
            const sizes = Array.from(sizesSet);

            // Set the state
            setColors(colors);
            setSizes(sizes);

            // Automatically select the first available color and size if only one option is present
            if (colors.length === 1) setSelectedColor(colors[0]);
            if (sizes.length === 1) setSelectedSize(sizes[0]);

          } catch (error) {
            console.error(error);
          }
        }


        setProduct(data);
      } catch (error) {
        console.error('Error loading product data:', error);
      }
    };

    loadProductData();
  }, [id]);

  useEffect(() => {
    const updateAllowedOptions = () => {
      if (!product || !product.variants) return;

      const variantData = JSON.parse(product.variants);

      let allowedColorsSet = new Set();
      let allowedSizesSet = new Set();
      let selectedPrice = null;

      // If both selectedColor and selectedSize are null, get all available options
      if (!selectedColor && !selectedSize) {
        variantData.forEach(variant => {
          if (variant.stock > 0) {
            allowedColorsSet.add(variant.color);
            allowedSizesSet.add(variant.size);
          }
        });
      }
      // If selectedColor is null, get all possible sizes for the selectedColor
      else if (selectedColor && !selectedSize) {
        variantData.forEach(variant => {
          if (variant.color === selectedColor && variant.stock > 0) {
            allowedSizesSet.add(variant.size);
          }
        });
      }
      // If selectedSize is null, get all possible colors for the selectedSize
      else if (!selectedColor && selectedSize) {
        variantData.forEach(variant => {
          if (variant.size === selectedSize && variant.stock > 0) {
            allowedColorsSet.add(variant.color);
          }
        });
      }

      // Convert sets to arrays
      const allowedColors = Array.from(allowedColorsSet);
      const allowedSizes = Array.from(allowedSizesSet);

      // Set the state
      setAllowedColors(allowedColors);
      setAllowedSizes(allowedSizes);
    };

    if (sizes.length !== 1 && colors.length !== 1) {
      updateAllowedOptions();
    }
  }, [selectedColor, selectedSize, product]);

  useEffect(() => {
    if (sizes.length !== 1 && colors.length !== 1) {
      setSelectedColor('');
    }
  }, [selectedSize]);


  const submit = async () => {
    const data = {
      name: product.name,
      price: selectedPrice,
      sku: id,
      photo: images[0],
      selectedProduct: id,
      selectedSize: selectedSize,
      selectedColor: selectedColor,
    };

    // Retrieve the cart from localStorage, or initialize an empty array if it doesn't exist
    let savedCart = JSON.parse(localStorage.getItem('cart')) || [];

    // Add the new data to the cart
    const updatedCart = [...savedCart, data];

    // Save the updated cart back to localStorage
    localStorage.setItem('cart', JSON.stringify(updatedCart));

    setCartItems(updatedCart)
  };


  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white pt-20">
      <div className="pb-16 pt-6 sm:pb-24">
        <div className="mx-auto mt-8 max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">
            <div className="lg:col-span-5 lg:col-start-8">
              <div className="flex justify-between">
                <h1 className="text-xl font-medium text-gray-900">{product.name}</h1>
                {selectedPrice !== null && (
                  <p className="text-xl font-medium text-gray-900">${selectedPrice}</p>
                )}
              </div>
              {/* Reviews */}
              <div className="mt-4">
                <h2 className="sr-only">Reviews</h2>
                <div className="flex items-center">
                  <p className="text-sm text-gray-700">
                    {product.stars}
                    <span className="sr-only"> out of 5 stars</span>
                  </p>
                  <div className="ml-1 flex items-center">
                    {[0, 1, 2, 3, 4].map((stars) => (
                      <StarIcon
                        key={stars}
                        aria-hidden="true"
                        className={classNames(
                          product.stars > stars ? 'text-yellow-400' : 'text-gray-200',
                          'h-5 w-5 flex-shrink-0',
                        )}
                      />
                    ))}
                  </div>
                  <div aria-hidden="true" className="ml-4 text-sm text-gray-300">
                    ·
                  </div>
                  <div className="ml-4 flex">
                    <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                      See all {product.reviews} reviews
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Image gallery */}
            <div className="mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
              <h2 className="sr-only">Images</h2>

              {images && (
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-3 lg:gap-8">
                  {images.map((image, index) => (
                    <img
                      key={image}
                      alt={image}
                      src={image}
                      className={classNames(
                        index === 0 ? 'lg:col-span-2 lg:row-span-2' : 'hidden lg:block',
                        'rounded-lg',
                      )}
                    />
                  ))}
                </div>
              )}
            </div>

            <div className="mt-8 lg:col-span-5">
              <form>
                {/* Color picker */}
                {colors.length !== 0 && (
                  <div>
                    {colors.length > 1 && (
                      <div>
                        <h2 className="text-sm font-medium text-gray-900">Color</h2>

                        <div className="flex flex-wrap gap-[12px]">
                          <div className="flex flex-wrap gap-2">
                            {colors.map((color) => (
                              <div
                                key={color}
                                className={`relative cursor-pointer rounded-full ${selectedColor === color ? 'border-[3px] border-black' : 'border-[3px] border-transparent'
                                  }`}
                              >
                                <div
                                  className={`flex justify-center items-center w-[33px] h-[33px] text-center rounded-full font-semibold border-[5px] border-white ${allowedColors.includes(color) ? '' : 'cursor-not-allowed opacity-50'
                                    }`}
                                  style={{
                                    backgroundColor: color,
                                    filter: allowedColors.includes(color) ? '' : 'grayscale(100%)',
                                  }}
                                  onClick={() => {
                                    if (allowedColors.includes(color)) setSelectedColor(color);
                                  }}
                                />
                              </div>
                            ))}
                          </div>

                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/* Size picker */}
                {sizes.length > 1 && (
                  <div className="mt-8">
                    <div className="flex items-center justify-between">
                      <h2 className="text-sm font-medium text-gray-900">Size</h2>
                      {/* <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                      See sizing chart
                    </a> */}
                    </div>
                    <div className="flex flex-wrap gap-4">
                      {sizes.map((size, index) => (
                        <div key={index} className={`${selectedSize === size ? 'border-[3px] border-indigo-600' : 'border-[3px] border-transparent'} rounded-lg p-0.5`}>
                          <div
                            key={index}
                            className={`flex justify-center items-center ${selectedSize === size ? 'border-2 border-transparent bg-indigo-600 text-white' : 'border-2'} w-[75px] h-[50px] text-center rounded-md font-semibold`}
                            onClick={() => { setSelectedSize(size) }}
                          >
                            {size}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <button
                  className={`mt-8 flex w-full items-center justify-center rounded-md border border-transparent px-8 py-3 text-base font-medium ${selectedColor && selectedSize ? 'bg-indigo-600 text-white hover:bg-indigo-700' : 'bg-gray-400 text-gray-700 cursor-not-allowed'
                    } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                  onClick={() => {
                    if (selectedColor && selectedSize) {
                      submit();
                    }
                  }}
                  disabled={!selectedColor || !selectedSize}
                >
                  Add to cart
                </button>

              </form>

              {/* Product details */}
              <div className="mt-10">
                <h2 className="text-sm font-medium text-gray-900">Description</h2>

                <div
                  dangerouslySetInnerHTML={{ __html: product.description }}
                  className="prose prose-sm mt-4 text-gray-500"
                />
              </div>

              {/* <div className="mt-8 border-t border-gray-200 pt-8">
                <h2 className="text-sm font-medium text-gray-900">Fabric &amp; Care</h2>

                <div className="prose prose-sm mt-4 text-gray-500">
                  <ul role="list">
                    {product.details.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div> */}

              {/* Policies */}
              <section aria-labelledby="policies-heading" className="mt-10">
                <h2 id="policies-heading" className="sr-only">
                  Our Policies
                </h2>

                <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                  {policies.map((policy) => (
                    <div key={policy.name} className="rounded-lg border border-gray-200 bg-gray-50 p-6 text-center">
                      <dt>
                        <policy.icon aria-hidden="true" className="mx-auto h-6 w-6 flex-shrink-0 text-gray-400" />
                        <span className="mt-4 text-sm font-medium text-gray-900">{policy.name}</span>
                      </dt>
                      <dd className="mt-1 text-sm text-gray-500">{policy.description}</dd>
                    </div>
                  ))}
                </dl>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductView;
