import React, { useState } from 'react';

const SimpleMessageField = ({ id, title, value, placeholder, handleChangeText, otherStyles, disabled, required, error }) => {
  const [charCount, setCharCount] = useState(value.length);
  const maxChars = 200;

  const handleTextChange = (text) => {
    if (text.length <= maxChars) {
      setCharCount(text.length);
      handleChangeText(text);
    }
  };

  return (
    <div className={`space-y-2 ${otherStyles}`}>
      <div className='flex'>
        <p className="text-base dark:text-gray-100 font-medium">{title}</p>
        <p className={`text-red-500 font-medium pl-2 ${required ? "block" : "hidden"}`}>*</p>
      </div>

      <div className={`border-2 border-black-200 bg-black-100 focus:border-secondary flex items-center justify-between rounded-2xl ${error ? "bg-red-500 bg-opacity-30 border-red-400 text-red-400" : "dark:text-white"}`}>
        <textarea
          id={id}
          className="flex-1 w-full px-4 py-4 rounded-2xl font-semibold text-base bg-transparent placeholder-gray-500"
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => handleTextChange(e.target.value)}
          rows="5"
        />
      </div>
      <div className="flex justify-between items-center">
        <p className="text-red-500 font-medium">{error}</p>
        <p className={`text-sm ${charCount >= maxChars ? 'text-red-500' : 'text-gray-500'}`}>
          {charCount}/{maxChars}
        </p>
      </div>
    </div>
  );
};

export default SimpleMessageField;
