import { useEffect, useState } from 'react';
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useStateContext } from '../../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';
import { config, getDocument } from '../../lib/appwrite';
import { PaypalCheckout } from '../../components';

const Cart = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const { cartItems, setCartItems } = useStateContext();
    const [subtotal, setSubtotal] = useState(0);
    
    const [total, setTotal] = useState(0.0)

    useEffect(() => {
        setTotal(subtotal)
    }, [subtotal])

    useEffect(() => {
        const fetchProducts = async () => {
            const combinedItems = [];

            cartItems.forEach(item => {
                // console.log(item)
                const existingItemIndex = combinedItems.findIndex(combinedItem =>
                    combinedItem.name === item.name &&
                    combinedItem.photo === item.photo &&
                    combinedItem.price === item.price &&
                    combinedItem.selectedColor === item.selectedColor &&
                    combinedItem.selectedProduct === item.selectedProduct &&
                    combinedItem.selectedSize === item.selectedSize
                );

                if (existingItemIndex !== -1) {
                    combinedItems[existingItemIndex].qty += 1;
                } else {
                    combinedItems.push({ ...item, qty: 1 });
                }
            });

            const updatedCartItems = await Promise.all(combinedItems.map(async (item) => {
                const itemData = await getDocument(config.merchandiseCollectionId, item.selectedProduct)
                const selectedSize = item.selectedSize;
                const selectedColor = item.selectedColor;
                const variants = JSON.parse(itemData.variants);

                // Find the variant with the matching size and color
                const variant = variants.find(
                    variant => variant.size === selectedSize && variant.color === selectedColor
                );

                return {
                    ...item,
                    inStock: variant ? parseInt(variant.stock) > 0 : false
                };
            }));


            setProducts(updatedCartItems);

            const total = combinedItems.reduce((acc, item) => acc + parseFloat(item.price) * parseInt(item.qty), 0);
            setSubtotal(total);

            // Update cart items

        };

        fetchProducts();
    }, [cartItems]);

    const handleRemoveItem = (selectedProduct) => {
        const updatedItems = cartItems.filter((item) => item.selectedProduct !== selectedProduct);
        setCartItems(updatedItems);
        localStorage.setItem('cart', JSON.stringify(updatedItems));
    };

    const addItem = (qty, product) => {
        const savedCart = JSON.parse(localStorage.getItem('cart')) || [];
        const updatedCart = [...savedCart, ...Array.from({ length: qty }, () => product)];
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        setCartItems(updatedCart);
    };

    const removeItem = (qty, product) => {
        const updatedCartItems = [...cartItems];
        for (let i = 0; i < qty; i++) {
            const itemIndex = updatedCartItems.findIndex(
                item =>
                    item.selectedProduct === product.selectedProduct &&
                    item.selectedColor === product.selectedColor &&
                    item.selectedSize === product.selectedSize
            );
            if (itemIndex !== -1) {
                updatedCartItems.splice(itemIndex, 1);
            }
        }
        setCartItems(updatedCartItems);
        localStorage.setItem('cart', JSON.stringify(updatedCartItems));
    };

    return (
        <div className="bg-white pt-20">
            <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Shopping Cart</h1>
                <div className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
                    <section aria-labelledby="cart-heading" className="lg:col-span-7">
                        <h2 id="cart-heading" className="sr-only">
                            Items in your shopping cart
                        </h2>

                        <ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">
                            {products.map((product, productIdx) => (
                                <li key={productIdx} className="flex py-6 sm:py-10">
                                    <div className="flex-shrink-0">
                                        <img
                                            alt={product.photo}
                                            src={product.photo}
                                            className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                                        />
                                    </div>

                                    <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                                            <div>
                                                <div className="flex justify-between">
                                                    <h3 className="text-sm">
                                                        <a href={product.href} className="font-medium text-gray-700 hover:text-gray-800">
                                                            {product.name}
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div className="mt-1 flex text-sm">
                                                    {product.selectedColor ? (
                                                        <div className={`rounded-full my-auto`}>
                                                            <div className={`flex justify-center items-center w-[15px] h-[15px] text-center rounded-full font-semibold`}
                                                                style={{ backgroundColor: product.selectedColor }}
                                                            >
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                    {product.selectedSize ? (
                                                        <p className="ml-4 border-l border-gray-200 pl-4 text-gray-500">{product.selectedSize}</p>
                                                    ) : null}
                                                </div>
                                                <p className="mt-1 text-sm font-medium text-gray-900">${product.price}</p>
                                            </div>

                                            <div className="mt-4 sm:mt-0 sm:pr-9">
                                                <label htmlFor={`quantity-${productIdx}`} className="sr-only">
                                                    Quantity, {product.name}
                                                </label>
                                                <select
                                                    id={`quantity-${productIdx}`}
                                                    name={`quantity-${productIdx}`}
                                                    value={product.qty}
                                                    onChange={(e) => {
                                                        const newQty = parseInt(e.target.value);
                                                        const originalQty = product.qty;

                                                        if (newQty < originalQty) {
                                                            removeItem(originalQty - newQty, product);
                                                        } else if (newQty > originalQty) {
                                                            addItem(newQty - originalQty, product);
                                                        }
                                                    }}
                                                    className="w-20 rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm p-2"
                                                >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                </select>


                                                <div className="absolute right-0 top-0">
                                                    <button
                                                        onClick={() => handleRemoveItem(product.selectedProduct)}
                                                        className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                                                    >
                                                        <span className="sr-only">Remove</span>
                                                        <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                                                    </button>
                                                </div>

                                            </div>
                                        </div>

                                        <p className="mt-4 flex space-x-2 text-sm text-gray-500">
                                            <span>{product.inStock ? 'In stock' : 'Out of stock'}</span>
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </section>

                    <section
                        aria-labelledby="summary-heading"
                        className="lg:col-span-5 lg:mt-0 lg:border-l lg:border-gray-200 lg:pt-16 pl-5"
                    >
                        <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                            Order summary
                        </h2>
                        <div className="mt-4">
                            <div className="space-y-6">
                                <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-base font-medium text-gray-900">
                                    <p>Subtotal</p>
                                    <p>${subtotal.toFixed(2)}</p>
                                </div>
                                <div className="flex items-center justify-between text-[14px] font-medium text-gray-900">
                                    <p>Shipping</p>
                                    <p>Free</p>
                                </div>
                                <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-base font-medium text-gray-900">
                                    <p>Total</p>
                                    <p>${subtotal.toFixed(2)}</p>
                                </div>
                            </div>

                            <div className="mt-6">
                                <PaypalCheckout price={total} />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Cart;
