import React, { useEffect, useState, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useStateContext } from '../../../../contexts/ContextProvider';
import EditableTable from '../../../../components/EditableTable';
import { Button } from '../../../../components';
import FormField from '../../../../components/FormField';
import { uploadPermissionRecord, updateDocument, config } from '../../../../lib/appwrite';

const EditAccessGroup = ({ view, setView, selectedRowData, setSelectedRowData, mode }) => {
    const { currentColor, account } = useStateContext();

    const [permissionName, setPermissionName] = useState('')
    const [permissionNameError, setPermissionNameError] = useState(false)
    const [pageAccess, setPageAccess] = useState([]); // Initialize pageAccess as an empty array
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (view && selectedRowData) {
            setPermissionName(selectedRowData.permissionName.toLowerCase())

            const editJSON = JSON.parse(selectedRowData.pageAccess);
            if (editJSON !== null) {
                setPageAccess(editJSON);
            }
        }
    }, [view, selectedRowData, mode]);

    const close_menu = () => {
        setPermissionName('')
        setPageAccess([])
        setSelectedRowData(null);
        setView(false);
        setIsLoading(false);
    };

    const submit = async () => {
        setIsLoading(true);

        try {
            let response;

            if (permissionName === '') { setPermissionNameError(true) } else { setPermissionNameError(false) }

            if (
                permissionName === ''
            ) {
                return
            }

            if (mode === 'edit') {
                const data = {
                    permissionName: permissionName.toLocaleLowerCase(),
                    customAccess: false,
                    pageAccess: JSON.stringify(pageAccess)
                }
                response = await updateDocument(selectedRowData.$id, data, config.permissionsCollectionId);
            } else if (mode === 'create') {
                response = await uploadPermissionRecord(permissionName.toLowerCase(), false, pageAccess)
            }

            if (response && response.error) {
                console.error(response.error);
            } else {
                close_menu();
            }
        } catch (error) {
            console.error('An error occurred during submission:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0'>
            <div className='float-right h-screen overflow-scroll dark:text-gray-200 bg-white dark:bg-secondary-dark-bg w-screen md:w-[600px]'>
                <div className='flex justify-between items-center p-4 ml-4'>
                    {mode === 'edit' && (
                        <p className='font-semibold text-xl'>Edit Permission Group</p>
                    )}
                    {mode === 'create' && (
                        <p className='font-semibold text-xl'>Create Permission Group</p>
                    )}
                    {mode === 'view' && (
                        <p className='font-semibold text-xl'>Permission Group</p>
                    )}
                    <button
                        type='button'
                        onClick={() => close_menu()}
                        style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                    >
                        <MdOutlineCancel />
                    </button>
                </div>

                <div className='flex-col border-t-1 border-color p-4 ml-4 space-y-6'>
                    <FormField
                        title="Permission Name"
                        placeholder=""
                        value={permissionName}
                        handleChangeText={(e) => setPermissionName(e)}
                        error={permissionNameError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <EditableTable
                        title="Accessible Pages"
                        data={pageAccess}
                        setData={setPageAccess}
                        rowActions={['Edit', 'Delete']}
                        addRow={true}
                        keyMapping={{
                            'Path': 'path',
                        }}
                    />

                    <div
                        onClick={() => {
                            if (!isLoading) {
                                setTimeout(submit, 100)
                            }
                        }}
                    >
                        <Button
                            bgColor={currentColor}
                            text="Submit"
                            size="md"
                            borderRadius={"12px"}
                            otherStyles="w-full items-center text-center font-semibold"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EditAccessGroup;