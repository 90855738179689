import React, { useEffect, useState } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { config, updateDocument } from '../lib/appwrite';
import { useStateContext } from '../contexts/ContextProvider';
import { Input1 } from '../components';
import { fetchOrderDetails } from '../lib/paypal';

const ViewOrder = ({ view, setView, selectedRowData, setSelectedRowData }) => {
    const { currentColor } = useStateContext();
    const [cartItems, setCartItems] = useState([]);
    const [orderData, setOrderData] = useState(null)

    useEffect(() => {
        const prefillInformation = async () => {
            if (view && selectedRowData) {
                // Ensure selectedRowData has required properties
                if (!selectedRowData.orderId) return;

                try {
                    const orderInformation = await fetchOrderDetails(selectedRowData.orderId);
                    setOrderData(orderInformation);
                    console.log(orderInformation);

                    const allCartItems = selectedRowData.cartItems?.flatMap(item => {
                        try {
                            const parsedRow = JSON.parse(item || '[]');
                            return parsedRow;
                        } catch (error) {
                            console.error('Error parsing cart_items:', error);
                            return [];
                        }
                    }) || []; // Default to empty array if cartItems is undefined

                    setCartItems(allCartItems);
                } catch (error) {
                    console.error('Error fetching order details:', error);
                }
            }
        };

        prefillInformation();
    }, [view, selectedRowData]);


    const close_menu = () => {
        setOrderData(null);
        setSelectedRowData(null)
        setView(false);
    };

    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0'>
            <div className='float-right h-screen overflow-y-auto dark:text-gray-200 bg-white dark:bg-secondary-dark-bg w-screen md:w-[600px]'>
                <div className='flex justify-between items-center p-4 ml-4'>
                    <p className='font-semibold text-xl'>Order Details</p>
                    <button
                        type='button'
                        onClick={close_menu}
                        style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                    >
                        <MdOutlineCancel />
                    </button>
                </div>

                <div className='flex-col border-t-1 border-color p-4 ml-4 space-y-6'>
                    <p className='font-semibold text-xl'>General Information</p>

                    {orderData && (
                        <div>
                            <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                <div className='duration-300 border-2 rounded-xl'>
                                    <Input1
                                        placeholder={'Order ID'}
                                        required={false}
                                        isFocused={false}
                                        setIsFocused={() => { }}
                                        value={selectedRowData.orderId}
                                        onChange={() => { }}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                <div className='duration-300 border-2 rounded-xl'>
                                    <Input1
                                        placeholder={'Date'}
                                        required={false}
                                        isFocused={false}
                                        setIsFocused={() => { }}
                                        value={selectedRowData.readableOrderTime}
                                        onChange={() => { }}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                <div className='duration-300 border-2 rounded-xl'>
                                    <Input1
                                        placeholder={'Name'}
                                        required={false}
                                        isFocused={false}
                                        setIsFocused={() => { }}
                                        value={`${selectedRowData.firstName} ${selectedRowData.lastName}`}
                                        onChange={() => { }}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                <div className='duration-300 border-2 rounded-xl'>
                                    <Input1
                                        placeholder={'Email'}
                                        required={false}
                                        isFocused={false}
                                        setIsFocused={() => { }}
                                        value={orderData.payer.email_address}
                                        onChange={() => { }}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                <div className='duration-300 border-2 rounded-xl'>
                                    <Input1
                                        placeholder={'Street Address'}
                                        required={false}
                                        isFocused={false}
                                        setIsFocused={() => { }}
                                        value={orderData.purchase_units[0].shipping.address.address_line_1}
                                        onChange={() => { }}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className='flex'>
                                <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                    <div className='duration-300 border-2 rounded-xl'>
                                        <Input1
                                            placeholder={'APT/Suite'}
                                            required={false}
                                            isFocused={false}
                                            setIsFocused={() => { }}
                                            value={orderData.purchase_units[0].shipping.address.address_line_2}
                                            onChange={() => { }}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                    <div className='duration-300 border-2 rounded-xl'>
                                        <Input1
                                            placeholder={'City'}
                                            required={false}
                                            isFocused={false}
                                            setIsFocused={() => { }}
                                            value={orderData.purchase_units[0].shipping.address.admin_area_2}
                                            onChange={() => { }}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex'>
                                <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                    <div className='duration-300 border-2 rounded-xl'>
                                        <Input1
                                            placeholder={'State'}
                                            required={false}
                                            isFocused={false}
                                            setIsFocused={() => { }}
                                            value={orderData.purchase_units[0].shipping.address.admin_area_1}
                                            onChange={() => { }}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                    <div className='duration-300 border-2 rounded-xl'>
                                        <Input1
                                            placeholder={'Zipcode'}
                                            required={false}
                                            isFocused={false}
                                            setIsFocused={() => { }}
                                            value={orderData.purchase_units[0].shipping.address.postal_code}
                                            onChange={() => { }}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                <div className='duration-300 border-2 rounded-xl'>
                                    <Input1
                                        placeholder={'Country'}
                                        required={false}
                                        isFocused={false}
                                        setIsFocused={() => { }}
                                        value={orderData.purchase_units[0].shipping.address.country_code}
                                        onChange={() => { }}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <p className='font-semibold text-xl pt-6'>Totals</p>

                            <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                <div className='duration-300 border-2 rounded-xl'>
                                    <Input1
                                        placeholder={'Gross Total'}
                                        required={false}
                                        isFocused={false}
                                        setIsFocused={() => { }}
                                        value={`$${orderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.gross_amount.value}`}
                                        onChange={() => { }}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                <div className='duration-300 border-2 rounded-xl'>
                                    <Input1
                                        placeholder={'Paypal Fee'}
                                        required={false}
                                        isFocused={false}
                                        setIsFocused={() => { }}
                                        value={`$${orderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.paypal_fee.value}`}
                                        onChange={() => { }}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
                                <div className='duration-300 border-2 rounded-xl'>
                                    <Input1
                                        placeholder={'Net Total'}
                                        required={false}
                                        isFocused={false}
                                        setIsFocused={() => { }}
                                        value={`$${orderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.net_amount.value}`}
                                        onChange={() => { }}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <p className='font-semibold text-xl'>Items</p>

                    <ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">
                        {cartItems.map((product, productIdx) => (
                            <li key={productIdx} className="flex py-2 px-3">
                                <div className="flex-shrink-0">
                                    <img
                                        alt={product.photo}
                                        src={product.photo}
                                        className="h-24 w-24 rounded-md object-cover object-center sm:h-20 sm:w-20"
                                    />
                                </div>

                                <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                                    <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                                        <div>
                                            <div className="flex justify-between">
                                                <h3 className="text-sm">
                                                    <a href={product.href} className="font-medium text-gray-700 hover:text-gray-800">
                                                        {product.name}
                                                    </a>
                                                </h3>
                                            </div>
                                            <div className="mt-1 flex text-sm">
                                                {product.selectedColor ? (
                                                    <div className={`rounded-full my-auto`}>
                                                        <div className={`flex justify-center items-center w-[15px] h-[15px] text-center rounded-full font-semibold`}
                                                            style={{ backgroundColor: product.selectedColor }}
                                                        >
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {product.selectedSize ? <p className="ml-4 text-gray-500">{product.selectedSize}</p> : null}
                                            </div>
                                            <p className="mt-1 text-sm font-medium text-gray-900">${product.price}</p>
                                        </div>
                                        <div className="mt-4 sm:mt-0 sm:pr-9 0">
                                            <div className="absolute right-0 top-0">
                                                <label htmlFor={`quantity-${productIdx}`} className="sr-only">
                                                    Quantity, {product.name}
                                                </label>
                                                <div className="mt-1">x{product.qty || 1}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>

                    {/* <p className='font-semibold text-xl pt-6'>Shipping</p>

          <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
            <div className='duration-300 border-2 rounded-xl'>
              <Input1
                placeholder={'Tracking ID'}
                required={false}
                isFocused={false}
                setIsFocused={() => {}}
                value={selectedRowData.trackingId}
                onChange={() => {}}
                disabled={true}
              />
            </div>
          </div>

          <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
            <div className='duration-300 border-2 rounded-xl'>
              <Input1
                placeholder={'Carrier'}
                required={false}
                isFocused={false}
                setIsFocused={() => {}}
                value={selectedRowData.carrier}
                onChange={() => {}}
                disabled={true}
              />
            </div>
          </div>

          <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
            <div className='duration-300 border-2 rounded-xl'>
              <Input1
                placeholder={'Shipping Method'}
                required={false}
                isFocused={false}
                setIsFocused={() => {}}
                value={selectedRowData.shippingMethod}
                onChange={() => {}}
                disabled={true}
              />
            </div>
          </div>

          <div className='w-full border-6 rounded-2xl border-4 border-transparent duration-100'>
            <div className='duration-300 border-2 rounded-xl'>
              <Input1
                placeholder={'Shipping Price'}
                required={false}
                isFocused={false}
                setIsFocused={() => {}}
                value={`$${selectedRowData.shipping_price}`}
                onChange={() => {}}
                disabled={true}
              />
            </div>
          </div> */}
                </div>
            </div>
        </div>
    );
};

export default ViewOrder;
