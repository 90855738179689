import React, { useState, useRef } from 'react';

const Input1 = ({ placeholder, required, isFocused, setIsFocused, value, onChange, disabled }) => {
    const inputRef = useRef(null);

    const handleFocus = () => {
        if (!disabled) {
            setIsFocused(true);
            inputRef.current.focus();
        }
    };

    return (
        <div className="relative h-14 transition-all duration-300" onClick={handleFocus}>
            <input
                ref={inputRef}
                type="text"
                className={`w-full h-full px-3 pt-3 text-base bg-transparent placeholder-transparent focus:outline-none outline-none border-none rounded-md transition-all duration-300`}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
            />
            <label
                className={`${disabled ? 'text-gray-400' : 'text-gray-600'} absolute left-3 transition-all duration-300 cursor-text ${isFocused || value !== '' ? 'text-xs -top-[-4px]' : 'text-md top-1/2 transform -translate-y-1/2'}`}
            >
                {placeholder}
                {required && (
                    <span className="text-gray-400 cursor-text"> (Required)</span>
                )}
            </label>
        </div>
    );
};

export default Input1;
