import React, { useState, useEffect } from 'react';
import { Header, ConfirmDelete, EditableTable, ConfirmAccept } from '../components'
import useAppwrite from '../lib/useAppwrite';
import { config, deleteDocument, getAllDocuments, getCurrentUser, updateDocument } from '../lib/appwrite';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import { FaCheckCircle, FaEye, FaPlus, FaRegEdit } from 'react-icons/fa';
import { FaCirclePlus, FaTrashCan } from "react-icons/fa6";
import { TbEye, TbEyeCog } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import { TiCancel } from "react-icons/ti";
import ViewOrder from './ViewOrder';


const Orders = () => {
  const navigate = useNavigate()
  const { currentColor } = useStateContext()

  const { data: user } = useAppwrite(
    () => getCurrentUser()
  );

  const [confirmCancel, setConfirmCancel] = useState(false)

  const { data: orders, isLoading, refetch } = useAppwrite(
    async () => {
      const documents = await getAllDocuments(config.ordersCollectionId, null, [{ 'status': 'ordered' }])
      const processedDocuments = process_data(documents)
      return processedDocuments
    }
  );

  const process_data = (documents) => {
    if (!documents) { return }

    // Function to convert ISO 8601 datetime string to readable format
    const convertToReadableDateTime = (isoString) => {
      // Split the date and time parts
      const [datePart, timePart] = isoString.split('T');
      // Remove milliseconds and timezone from time part
      const [timeWithoutMillis] = timePart.split('+')[0].split('.');

      // Format the date part
      const [year, month, day] = datePart.split('-');
      const formattedDate = `${month}/${day}/${year}`; // e.g., "08/06/2024"

      // Format the time part
      const [hours, minutes, seconds] = timeWithoutMillis.split(':');
      const formattedTime = `${hours}:${minutes}:${seconds}`; // e.g., "22:38:11"

      // Return the readable date and time
      return `${formattedDate} ${formattedTime}`;
    };

    // Process each document to include a readable date and time
    const processedDocuments = documents.map(doc => {
      const readableOrderTime = convertToReadableDateTime(doc.orderDate);

      // Return a new document object with the readable date and time
      return {
        ...doc,
        readableOrderTime,
      };
    });

    return processedDocuments;
  }

  const [viewOrder, setViewOrder] = useState(false)

  // Table variables
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [reset, setReset] = useState(false)
  const [messages, setMessages] = useState([])

  useEffect(() => {
    if (!selectedRowData) {
      setReset(true)
    }
  }, [selectedRowData])

  const handleCancel = async (id) => {
    try {
      await updateDocument(selectedRowData.$id, { "status": "canceled" }, config.ordersCollectionId);
      try {
        refetch()
      } catch (error) {
        console.log(error)
      };
      setSelectedRowData(null);
      setConfirmCancel(false);
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  const [confirmAccept, setConfirmAccept] = useState(false)
  const handleComplete = async (id) => {
    try {
      await updateDocument(selectedRowData.$id, { "status": "complete" }, config.ordersCollectionId);
      try {
        refetch()
      } catch (error) {
        console.log(error)
      };
      setSelectedRowData(null);
      setConfirmAccept(false);
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };


  useEffect(() => {
    const documents = process_data(orders);
  }, [orders]);


  const handleRowSelect = (data) => {
    setSelectedRowData(data);
  };


  return (
    <div className="m-6 p-10 md:m-10 mt-24 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <div className='flex'>
        <div className={`${viewOrder ? 'block' : 'hidden'} overflow-scroll`}>
          <ViewOrder view={viewOrder} setView={setViewOrder} selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} mode={'view'} />
        </div>
        <Header category="" title="Orders" isLoading={isLoading} />
      </div>
      <div className='flex flex-1 justify-end items-center my-2'>
        <div className={`${selectedRowData && user.permissions ===
          'admin' ? 'block' : 'hidden'}`}>
          <TooltipComponent content="View Order" position='TopCenter'>
            <button
              type='button'
              onClick={() => setViewOrder(true)}
              style={{ borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaEye className='text-xl' style={{ color: currentColor }} />
            </button>
          </TooltipComponent>
        </div>
        <div className={`${selectedRowData && user.permissions ===
          'admin' ? 'block' : 'hidden'}`}>
          <TooltipComponent content="Mark Order as Complete" position='TopCenter'>
            <button
              type='button'
              onClick={() => setConfirmAccept(true)}
              style={{ borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaCheckCircle className='text-xl' style={{ color: currentColor }} />
            </button>
          </TooltipComponent>
        </div>
        <div className={`${selectedRowData && user.permissions ===
          'admin' ? 'block' : 'hidden'}`}>
          <TooltipComponent content="Cancel Order" position='TopCenter'>
            <button
              type='button'
              onClick={() => setConfirmCancel(true)}
              style={{ borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <TiCancel className='text-3xl text-red-500' />
            </button>
          </TooltipComponent>
        </div>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl overflow-hidden sm:px-6 lg:px-8">
          <h2 className="sr-only">Orders</h2>

          <EditableTable
            title=""
            data={orders}
            rowActions={[]}
            setData={() => { }}
            keyMapping={{
              'Date': 'readableOrderTime',
              'Order ID': 'orderId',
              'First Name': 'firstName',
              'Last Name': 'lastName'
            }}
            onRowClick={handleRowSelect} // Assuming handleRowSelect is a defined function
            reset={reset}
            setReset={setReset}
            selectable={true}
          />
          <ConfirmDelete
            title="Confirm Cancellation?"
            text="Are you sure you want to delete the following? This action cannot be undone. You will need to refund this order in your Paypal Dashboard!"
            confirmButtonText="Confirm"
            cancelButtonText="Back"
            visible={confirmCancel}
            setVisible={setConfirmCancel}
            handleConfirm={() => handleCancel()}
          />

          <ConfirmDelete
            title="Confirm Completed Order?"
            text="Are you sure you want to complete the order? This will remove the order from the orders page!"
            confirmButtonText="Confirm"
            cancelButtonText="Back"
            visible={confirmAccept}
            setVisible={setConfirmAccept}
            handleConfirm={() => handleComplete()}
          />
        </div>
      </div>
    </div>
  );
};

export default Orders;