import React from 'react'
import { FaTiktok } from 'react-icons/fa'
import { FaFacebook, FaInstagram, FaLinkedin, FaSkype, FaTwitter } from 'react-icons/fa6'

const TeamAllianceCard = ({team}) => {
    return (
        <div className='max-w-[300px] p-3'>
            <div className='mb-[20px] overflow-hidden rounded-[5px] bg-[#fff] shadow-md'>
                <div className='relative overflow-hidden'>
                    <img src={team.photo} alt="" />
                    <div className='absolute left-0 bottom-[30px] right-0 ease-in-out transition-all duration-300 text-center hover:opacity-[100%] hover:bottom-[15px] opacity-0'>
                        <a className='hover:bg-[#a97c29] transition-all duration-300 mx-[3px] rounded-[50px] w-[36px] h-[36px] bg-[#000081] inline-flex items-center justify-center ease-in-out text-[#fff]' href={team.twitter}><FaTwitter className='text-[18px]' /></a>
                        <a className='hover:bg-[#a97c29] transition-all duration-300 mx-[3px] rounded-[50px] w-[36px] h-[36px] bg-[#000081] inline-flex items-center justify-center ease-in-out text-[#fff]' href={team.facebook}><FaFacebook className='text-[18px]' /></a>
                        <a className='hover:bg-[#a97c29] transition-all duration-300 mx-[3px] rounded-[50px] w-[36px] h-[36px] bg-[#000081] inline-flex items-center justify-center ease-in-out text-[#fff]' href={team.instagram}><FaInstagram className='text-[18px]' /></a>
                        <a className='hover:bg-[#a97c29] transition-all duration-300 mx-[3px] rounded-[50px] w-[36px] h-[36px] bg-[#000081] inline-flex items-center justify-center ease-in-out text-[#fff]' href={team.tiktok}><FaTiktok className='text-[18px]' /></a>
                    </div>
                </div>
                <div className='py-[25px] px[15px] p-5'>
                    <a>
                        <h4 className='font-[700] mb-[5px] text-[18px] text-[#0f394c]'>{team.name}</h4>
                    </a>
                    <span className='block text-[13px] font-[400] text-[#aaaaaa]'>{team.slogan}</span>
                </div>
            </div>
        </div>
    )
}

export default TeamAllianceCard