import React, { useState, useRef } from 'react';

const MessageField = ({ placeholder, required, isFocused, setIsFocused, value, onChange, disabled, error }) => {
  const inputRef = useRef(null);

  const handleFocus = () => {
    if (!disabled) {
      setIsFocused(true);
      inputRef.current.focus();
    }
  };

  const [charCount, setCharCount] = useState(value.length);
  const maxChars = 200;

    const handleTextChange = (text) => {
    if (text.length <= maxChars) {
      setCharCount(text.length);
      onChange(text);
    }
  };

  return (
    <div className="relative transition-all duration-300" onClick={handleFocus}>
      <textarea
        ref={inputRef}
        type="text"
        className={`w-full h-full px-3 pt-3 text-base bg-transparent placeholder-transparent focus:outline-none outline-none border-none rounded-md transition-all duration-300`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleTextChange(e.target.value)}
        disabled={disabled}
        rows='5'
      />
      <label
        className={`${disabled ? 'text-gray-400' : 'text-gray-600'} absolute left-3 top-4 transition-all duration-300 cursor-text ${isFocused || value !== '' ? 'text-xs -top-1' : 'text-md top-1/2 transform -translate-y-1/2'}`}
      >
        {placeholder}
        {required && (
          <span className="text-gray-400 cursor-text"> (Required)</span>
        )}
      </label>
      <div className="flex justify-between items-center">
        <p className="text-red-500 font-medium">{error}</p>
        <p className={`text-sm ${charCount >= maxChars ? 'text-red-500' : 'text-gray-500'}`}>
          {charCount}/{maxChars}
        </p>
      </div>
    </div>
  );
};

export default MessageField;

