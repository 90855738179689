import React, { useState } from 'react';
import { FaQuestionCircle } from 'react-icons/fa'; // Import a question mark icon from react-icons

const WhatIsThis = ({ tooltipText }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="relative inline-flex items-center text-xs font-semibold text-gray-600">
            <span>WHAT IS THIS</span>
            <div
                className="ml-2 flex items-center cursor-pointer"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <FaQuestionCircle className="text-gray-500" />
                {isHovered && (
                    <div className="absolute top-full left-0 mt-2 px-3 py-2 bg-gray-800 text-white text-sm rounded shadow-lg w-48 z-50">
                        {tooltipText}
                    </div>
                )}
            </div>
        </div>
    );
};

export default WhatIsThis;
