// src/ToggleSwitch.js
import React from 'react';

const ToggleSwitch = ({ checked, onChange }) => {
  return (
    <label className="relative cursor-pointer">
      <input
        type="checkbox"
        className="sr-only"
        checked={checked}
        onChange={onChange}
      />
      <div className={`w-11 h-6 ${checked ? 'bg-green-500' : 'bg-gray-200'} rounded-full transition-colors duration-300 ease-in-out`}>
        <div
          className={`absolute top-0.5 left-0.5 w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform duration-300 ease-in-out ${checked ? 'transform translate-x-full' : ''}`}
        ></div>
      </div>
    </label>
  );
};

export default ToggleSwitch;
