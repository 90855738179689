import React, { useEffect, useState } from "react";
import { WebNavbar, Footer, Sidebar, ThemeSettings, Navbar } from "./components";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import * as Pages from "./pages/index";
import { useStateContext } from "./contexts/ContextProvider";
import ProtectedContent from "./ProtectedContent";
import AOS from 'aos';
import 'aos/dist/aos.css';
import useAppwrite from "./lib/useAppwrite";
import { getCurrentUser } from "./lib/appwrite";

const App = () => {
  const {
    activeMenu,
    themeSettings,
    setThemeSettings,
    currentColor,
    currentMode,
    isLoggedIn,
  } = useStateContext();

  useEffect(() => {
    AOS.init({
      duration: 1000, // you can set global duration here
    });
  }, []);

  return (
    <div className={`${currentMode === "Dark" ? "dark" : ""}`}>
      <BrowserRouter>
        {isLoggedIn ? (
          <div className="flex relative dark:bg-main-dark-bg">
            <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
              <TooltipComponent content="Settings" position="Top">
                <button
                  type="button"
                  className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
                  style={{ background: currentColor, borderRadius: "50%" }}
                  onClick={() => setThemeSettings(true)}
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>
            <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
              <TooltipComponent content="Settings" position="Top">
                <button
                  type="button"
                  className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
                  style={{ background: currentColor, borderRadius: "50%" }}
                  onClick={() => setThemeSettings(true)}
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>
            {activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar />
              </div>
            )}
            <div
              className={`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full ${activeMenu ? "md:ml-72" : "flex-2"
                }`}
            >
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                <Navbar />
              </div>
              <div>
                {themeSettings && <ThemeSettings />}

                <ProtectedContent />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex relative dark:bg-main-dark-bg">
            <div
              className={`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full flex-2`}
            >
              <div>
                {themeSettings && <ThemeSettings />}
                <WebNavbar />
                <Routes>
                  <Route path="/" element={<Pages.Home />} />
                  {/* <Route path='/review' element={<Pages.Review />} /> */}
                  {/* <Route path='/booking' element={<Pages.Booking />} /> */}
                  <Route path="/login" element={<Pages.SignIn />} />
                  <Route path="/signup" element={<Pages.SignUp />} />
                  <Route path="/products/:id" element={<Pages.ProductView />} />
                  <Route path="/cart" element={<Pages.Cart />} />
                  <Route path="/thank-you/:id" element={<Pages.Thankyou />} />
                </Routes>
                <Footer />
              </div>
            </div>
          </div>
        )}
      </BrowserRouter>
    </div>
  );
};

{
  /* <Routes>
  <Route path="/" element={<Pages.Home />} />
</Routes>; */
}

export default App;
