import React, { useRef, useEffect } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

const HorizontalSwiper = ({ children }) => {
    const swiperRef = useRef(null);

    useEffect(() => {
        if (swiperRef.current) {
            new Swiper(swiperRef.current, {
                slidesPerView: 'auto',
                spaceBetween: 30, // adjust as needed
                // Add more Swiper.js options as needed
            });
        }
    }, []);

    return (
        <div>
            <div ref={swiperRef} className="swiper-container overflow-hidden">
                <div className="swiper-wrapper">
                    {children.map((child, index) => (
                        <div key={index} className="swiper-slide">
                            {child}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HorizontalSwiper;
