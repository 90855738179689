import React, { useEffect, useState } from 'react'
import Button from './Button'

const YesNoButton = ({ title, option1, option2, handleChangeText, required, error, value }) => {
    const [selected, setSelected] = useState()

    const handleButtonSelect = (option) => {
        setSelected(option)
        handleChangeText(option)
    }

    useEffect(() => {
        const update_value = async () => {
            setSelected(value)
        }

        update_value()
    }, [value])

    return (
        <div>
            <div className='flex'>
                <p className="text-base dark:text-gray-100 font-medium">{title}</p>
                <p className={`text-red-500 font-medium pl-2 ${required ? "block" : "hidden"}`}>*</p>
            </div>
            <div className='flex space-x-2 pt-2'>
                <div onClick={() => handleButtonSelect(option1)}>
                    <Button
                        bgColor={error && selected === '' ? 'rgba(239,68,68,0.5)' : selected === option1 ? "#F89E34" : 'rgba(107,114,128,0.1)'}
                        text={option1.charAt(0).toUpperCase() + option1.slice(1)}
                        borderRadius="[10px]"
                        size="md"
                        otherStyles={`${error && selected === '' ? 'border-2 border-red-400' : ''}`}
                    />
                </div>
                <div onClick={() => handleButtonSelect(option2)}>
                    <Button
                        bgColor={error && selected === '' ? 'rgba(239,68,68,0.5)' : selected === option2 ? "#F89E34" : 'rgba(107,114,128,0.1)'}
                        text={option2.charAt(0).toUpperCase() + option2.slice(1)}
                        borderRadius="[10px]"
                        size="md"
                        otherStyles={`${error && selected === '' ? 'border-2 border-red-400' : ''}`}
                    />
                </div>
            </div>
        </div>
    )
}

export default YesNoButton