import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { config, getAllDocuments, getDocument, updateDocument, uploadData } from '../../lib/appwrite';
import { fetchOrderDetails } from '../../lib/paypal';

const Thankyou = () => {
    const navigate = useNavigate();
    const { setCartItems, cartItems } = useStateContext()
    const { id } = useParams();

    useEffect(() => {
        const handleLoad = async () => {
            if (id && cartItems.length > 0) {
                const orderDetails = await fetchOrderDetails(id)
                if (!orderDetails) { return }

                const orderedItems = cartItems.map((item) => {
                    return JSON.stringify(item)
                })

                const saveData = {
                    orderId: id,
                    orderDate: orderDetails.create_time,
                    firstName: orderDetails.payer.name.given_name,
                    lastName: orderDetails.payer.name.surname,
                    cartItems: orderedItems,
                    status: "ordered"
                }

                // console.log(saveData)
                const response = await uploadData(saveData, config.ordersCollectionId)

                // Loop through cartItems
                for (const cartItem of cartItems) {
                    // Find the corresponding merchandise response by name
                    const merchResponse = await getAllDocuments(config.merchandiseCollectionId)
                    const merch = merchResponse.find(item => item.name === cartItem.name);

                    if (merch) {
                        // Parse the variants
                        const variants = JSON.parse(merch.variants);

                        // Find the variant that matches both selectedColor and selectedSize
                        const matchedVariant = variants.find(variant =>
                            variant.color === cartItem.selectedColor && variant.size === cartItem.selectedSize
                        );

                        if (matchedVariant) {
                            // Determine the quantity to reduce
                            const qty = cartItem.qty || 1;

                            // Update the stock
                            const currentStock = parseInt(matchedVariant.stock, 10);
                            const newStock = currentStock - qty;

                            // Ensure stock is not negative
                            matchedVariant.stock = newStock < 0 ? '0' : newStock.toString();

                            // Log the updated variant for verification
                            await updateDocument(merch.$id, { variants: JSON.stringify(variants) }, config.merchandiseCollectionId)

                            // console.log(`Updated Variant for ${cartItem.name}:`, cartItem.selectedColor, cartItem.selectedSize, merch.$id);
                        } else {
                            console.log(`No matching variant found for ${cartItem.name} with color ${cartItem.selectedColor} and size ${cartItem.selectedSize}`);
                        }
                    } else {
                        console.log(`No merchandise found for ${cartItem.name}`);
                    }
                }

                setCartItems([]);
                localStorage.removeItem('cart')
            }
        }

        handleLoad()
    }, [])

    return (
        <div className="flex h-screen items-center justify-center">
            <div>
                <div className="flex flex-col items-center space-y-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-28 w-28 text-green-600" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" strokeWidth="1">
                        <path strokeLinecap="round" strokeLinejoin="round"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <h1 className="text-4xl font-bold">Thank You !</h1>
                    <p>Thank you for your order!</p>
                    <div
                        onClick={() => { navigate('/') }}
                    >
                        <Button
                            text={"Home"}
                            size={"sm"}
                            bgColor={"#000081"}
                            borderRadius={"10px"}
                            color={"white"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Thankyou