import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, FormField } from '../../components'
import { useStateContext } from '../../contexts/ContextProvider';
import { signIn, getCurrentUser, createUser } from '../../lib/appwrite';
import colors from '../../assets/colors';

const SignUp = (props) => {
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [signInError, setSignInError] = useState('')
  const [isSubmitting, setSubmitting] = useState(false);
  const { setIsLoggedIn } = useStateContext();

  const { currentColor, setAccount } = useStateContext();

  const submit = async () => {
    if (name === '') { setNameError(true) } else { setNameError(false) }
    if (email === '') { setEmailError(true) } else { setEmailError(false) }
    if (password === '') { setPasswordError(true) } else { setPasswordError(false) } 
    if (name === '' || email === "" || password === "") {
      setSignInError("Please fill in all fields");
      return
    }

    setSubmitting(true);

    try {
      await createUser(email, password, name)

      const result = await getCurrentUser();
      setAccount(result)
      setIsLoggedIn(true);

    } catch (error) {
      if (error.message === 'AppwriteException: Invalid `password` param: Password must be between 8 and 265 characters long, and should not be one of the commonly used password.') {
        setPasswordError(true)
        setSignInError('Password must be between 8 and 265 characters long, and should not be one of the commonly used passwords.')
      }
      console.log(error.message)
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen dark:bg-main-dark-bg">
      <div className="font-bold text-4xl mb-4 dark:text-gray-100">MVPBME</div>
      <div className='p-5 space-y-4'>
        <FormField
          title="Name"
          value={name}
          handleChangeText={(e) => setName(e)}
          placeholder="Enter your name"
          error={nameError}
        />
        <FormField
          title="Email"
          value={email}
          handleChangeText={(e) => setEmail(e)}
          placeholder="Enter your email"
          error={emailError}
        />
        <FormField
          title="Password"
          value={password}
          handleChangeText={(e) => setPassword(e)}
          placeholder="Enter your password"
          error={passwordError}
        />
        <p>{signInError}</p>
      </div>

      <a href='/login'>
        Log In
      </a>

      <div
        className='pt-8'
        onClick={submit}
      >
        <Button
          bgColor={colors.mainTheme}
          text="Sign Up"
          borderRadius="10px"
          size="md"
          color={'white'}
        />
      </div>
    </div>
  );
}

export default SignUp