import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const FormField = ({ id, title, value, placeholder, handleChangeText, otherStyles, error, type, required, disabled }) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputType = type
    ? type
    : (title === 'Password' && !showPassword)
      ? 'password'
      : 'text';

  return (
    <div className={`space-y-2 ${otherStyles}`}>
      <div className='flex'>
        <p className="text-base dark:text-gray-100 font-medium">{title}</p>
        <p className={`font-medium text-base text-red-500 pl-2 ${required ? "block" : "hidden"}`}>*</p>
      </div>

      <div className={`relative border-2 border-black-200 bg-black-100 rounded-2xl focus-within:border-secondary ${error ? "bg-red-500 bg-opacity-30 border-red-400 text-red-400" : "dark:text-white"}`}>
        <input
          id={id}
          className="w-full h-10 px-4 font-semibold text-base bg-transparent placeholder-gray-500 rounded-2xl"
          value={value}
          placeholder={placeholder}
          onChange={(e) => handleChangeText(e.target.value)}
          type={inputType}
          disabled={disabled}
        />

        {title === 'Password' && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className='absolute top-1/2 right-4 transform -translate-y-1/2'
          >
            {!showPassword ? (
              <FaEye className='dark:text-gray-100' />
            ) : (
              <FaEyeSlash className='dark:text-gray-100' />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default FormField;