import React, { useState } from 'react';

const CalendarSelect = ({ selectedDay, setSelectedDay }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const handlePreviousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const renderDaysOfWeek = () => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return daysOfWeek.map((day) => (
      <div key={day} className="py-2 text-center font-bold text-gray-600 dark:text-gray-400">
        {day}
      </div>
    ));
  };

  const renderDates = () => {
    const daysInMonth = getDaysInMonth(currentDate);
    const firstDayOfMonth = getFirstDayOfMonth(currentDate);
    const days = [];

    // Fill initial empty days
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="py-2"></div>);
    }

    // Fill days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const isSelected = selectedDay?.getDate() === day && selectedDay?.getMonth() === currentDate.getMonth() && selectedDay?.getFullYear() === currentDate.getFullYear();
      days.push(
        <div
          key={day}
          className={`py-2 text-center cursor-pointer ${isSelected ? 'bg-blue-500 text-white rounded' : ''}`}
          onClick={() => setSelectedDay(new Date(currentDate.getFullYear(), currentDate.getMonth(), day))}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  const getMonthName = (date) => {
    return date.toLocaleString('default', { month: 'long' });
  };

  return (
    <div className="w-full max-w-sm mx-auto bg-white dark:bg-secondary-dark-bg shadow-md rounded-lg">
      <div className="flex justify-between items-center p-4 border-b">
        <button
          onClick={handlePreviousMonth}
          className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
        >
          Previous
        </button>
        <h2 className="text-lg font-semibold dark:text-white">
          {getMonthName(currentDate)} {currentDate.getFullYear()}
        </h2>
        <button
          onClick={handleNextMonth}
          className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
        >
          Next
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1 p-4 dark:text-white">
        {renderDaysOfWeek()}
        {renderDates()}
      </div>
    </div>
  );
};

export default CalendarSelect;
